import { select, all, put, takeLatest, call, race, take, delay } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import { resetState } from 'redux-localstore';
import currency from 'currency.js';
import axios from 'axios';

import api from '~/services/api';
import toast from '~/services/toast';
import print from '~/services/print';
import history from '~/services/history';
import pdvService from '~/store/modules/pdv/pdv-service';
import { selectorFechamento } from '~/store/modules/pdv/pdv-selectors';
import {
  getDataHoraAtualComTimeZone,
  selectorParametroAsBoolean,
  selectorRotina
} from '~/store/modules/auth/auth-selectors';
import {
  APP_ERROR,
  API_BASE_URL,
  API_NODE_URL,
  API_TERMINAL_URL,
  API_LOCAL_URL,
  STATUS_PDV,
  PDV_ACTION,
  TIPO_DOCUMENTO,
  DOCUMENTO_TRANSMISSAO,
  MODELO_DOCUMENTO,
  APPLICATION
} from '~/constants';
import {
  INICIAR_VENDA_REQUEST,
  ALTERAR_DOCUMENTO,
  FECHAR_VENDA_REQUEST,
  FECHAR_VENDA_SUCCESS,
  FECHAR_VENDA_ERROR,
  INICIAR_PAGAMENTO,
  SELECIONAR_PRODUTO_REQUEST,
  IDENTIFICAR_CLIENTE_REQUEST,
  ADICIONAR_PRODUTO_REQUEST,
  REMOVER_PRODUTO_REQUEST,
  CANCELAR_PRODUTO_REQUEST,
  ATUALIZAR_QUANTIDADE_REQUEST,
  SELECIONAR_VENDEDOR,
  SELECIONAR_PROFISSIONAL,
  SELECIONAR_CONDICAO_PAGAMENTO_REQUEST,
  SELECIONAR_MEIO_PAGAMENTO_REQUEST,
  ATUALIZAR_VALOR_ENTRADA,
  ADICIONAR_PARCELAS_REQUEST,
  ADICIONAR_PAGAMENTO_REQUEST,
  REMOVER_PAGAMENTOS_REQUEST,
  SELECIONAR_OPERACAO_REQUEST,
  SALVAR_ENDERECO_CLIENTE_REQUEST,
  ALTERAR_TIPO_DOCUMENTO_REQUEST,
  ATUALIZAR_VALOR_RECEBIDO,
  ATUALIZAR_PRECO_VENDA_PRODUTO,
  SELECIONAR_OUTRO_PAGAMENTO,
  ATUALIZAR_PARCELA_FECHAMENTO,
  GERAR_DOCUMENTO_OFFLINE,
  IMPORTAR_ORCAMENTO,
  IMPORTAR_PEDIDDO,
  EDITAR_QUANTIDADE_ITEM_VENDIDO,
  SELECIONAR_ADD_PRODUTO_REQUEST
} from '~/store/modules/pdv/pdv-constants';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import * as appActions from '~/store/modules/app/app-actions';
import { INITIAL_STATE } from '~/store/modules/pdv/pdv-reducer';
import {
  atualizaTotais,
  baseMontaItem,
  BASE_ITEM,
  calculaValorTotalItem
} from '~/store/modules/pdv/pdv-helper';
import { getParametroAsBoolean } from '~/utils/parametros';
import { getApplicationIsOnline } from '../app/app-selectors';
import normalizeError from '~/utils/normalize-error';
import signalr from '~/services/signalr';
import moment from 'moment-timezone';

const isWeb = APPLICATION === 'web';

const DELAY_RETRY_MILESEGUNDOS = 5000; //5 SEGUNDOS

let cancel;
const CancelToken = axios.CancelToken;

export function* iniciarVenda({ tipoDocumento, venda, limparVenda, confirmaLogin }) {
  let numero = 0;
  try {
    if (limparVenda) {
      yield call(resetState);
      yield put(pdvActions.limparVenda);
    }

    yield put(pdvActions.setShowModalLoadingMensagem({ show: true }));
    yield put(pdvActions.updateLoadingMensagem('Inicializando a venda, aguarde...'));

    console.tron.log('iniciarVenda', { venda });

    const terminal = yield select(state => state?.app?.terminal);
    const empresaId = yield select(state => state?.auth?.empresa?.id);
    const isOnline = yield select(getApplicationIsOnline());
    const dataAtual = yield select(getDataHoraAtualComTimeZone());

    const obrigaMeioPagamento = yield select(selectorParametroAsBoolean('PP001'));
    const parametroConfirmaLogin = yield select(selectorParametroAsBoolean('PP002'));
    const exibirModalCliente = yield select(selectorParametroAsBoolean('PP003'));

    const operacaoFilter = ['pdvExclusivo eq true'];
    switch (tipoDocumento) {
      case TIPO_DOCUMENTO.ORCAMENTO:
        operacaoFilter.push('orcamentoSerie gt 0');
        break;
      case TIPO_DOCUMENTO.PEDIDO:
        operacaoFilter.push('pedidoSerie gt 0');
        break;
      case TIPO_DOCUMENTO.DOCUMENTOSAIDA:
        operacaoFilter.push("operacao eq 'S'");
        operacaoFilter.push('documentoFinalidade eq 1');
        break;

      default:
        break;
    }

    const limpaChache = () => {
      sessionStorage.removeItem('cacheEmpresaId');
      sessionStorage.removeItem('cacheVendedores');
      sessionStorage.removeItem('cachedOperacaoFilter');
      sessionStorage.removeItem('cacheOperacoes');
      sessionStorage.removeItem('condicoesPagamentos');
      sessionStorage.removeItem('bandeiras');
      sessionStorage.removeItem('bancos');
      sessionStorage.removeItem('userData');
    }

    // Limpar cache a cada x minutos
    const limpaCacheTempo = 30;
    const timeChaced = sessionStorage.getItem('cacheTime');
    if(!timeChaced) {
      sessionStorage.setItem('cacheTime', moment());
    } else {
      const horaAtual = moment();
      const diff = horaAtual.diff(timeChaced, 'minutes');
      if(diff > limpaCacheTempo) {
        limpaChache();
        sessionStorage.setItem('cacheTime', moment());
      }

    }


    const cacheEmpresaId = sessionStorage.getItem('cacheEmpresaId') ? Number(sessionStorage.getItem('cacheEmpresaId')) : null;
    let vendedores = [];
    if(cacheEmpresaId === empresaId && sessionStorage.getItem('cacheVendedores')) {
      vendedores = JSON.parse(sessionStorage.getItem('cacheVendedores'));
    } else {
      const { data: vendedoresData } = isOnline
      ? yield call(
          api.get,
          `${API_BASE_URL}/v1/consulta/vendedor?$orderby=nome&$select=${[
            'nome',
            'cpfCnpj',
            'natureza',
            'id'
          ].join(
            ','
          )}&$filter=desativado eq false&$expand=fatEquipevenda($expand=operacoes)&v=${empresaId}`
        )
      : yield call(api.get, `${API_LOCAL_URL}/faturamento/vendedor/`);
      sessionStorage.setItem('cacheVendedores', JSON.stringify(vendedoresData));
      vendedores = vendedoresData;
    }


    let fatVendedorId = venda?.fatVendedor?.id ?? venda?.fatVendedorId;
    if (!fatVendedorId) {
      fatVendedorId = yield select(state => state?.auth?.vendedor?.id);
    }

    const vendedorIdUser = yield select(state => state?.auth?.vendedor?.id);

    const vendedorUser = vendedores?.find(x => x.id === vendedorIdUser);

    const fatVendedor = vendedores?.find(x => x.id === fatVendedorId);

    let operacoes = [];
    if (isOnline) {
        const cachedOperacaoFilter = sessionStorage.getItem('cachedOperacaoFilter') ? JSON.parse(sessionStorage.getItem('cachedOperacaoFilter')) : null;
        if(cachedOperacaoFilter && cachedOperacaoFilter[0] === operacaoFilter[0] && cachedOperacaoFilter[1] === operacaoFilter[1] && cacheEmpresaId === empresaId) {
          const data = JSON.parse(sessionStorage.getItem('cachedDataOperacao'));

          if (data) {  
            const _operacoes = JSON.parse(sessionStorage.getItem('cacheOperacoes'));
            if (_operacoes) {
              operacoes = [..._operacoes.map(x => x.data)];
            }
          }
      } else {
        const { data } = yield call(
          api.get,
          `${API_BASE_URL}/v1/consulta/operacao?$filter=${operacaoFilter.join(' and ')}&$select=id`
        );

        sessionStorage.setItem('cachedDataOperacao', JSON.stringify(data));
        sessionStorage.setItem('cachedOperacaoFilter', JSON.stringify(operacaoFilter));
  
        if (data) {
          const promisedOperacao = data?.map(item => {
            return call(api.get, `${API_BASE_URL}/v1/fiscal/operacao/${item?.id}`);
          });
          const _operacoes = yield all(promisedOperacao);
          sessionStorage.setItem('cacheOperacoes', JSON.stringify(_operacoes));
          if (_operacoes) {
            operacoes = [..._operacoes.map(x => x.data)];
          }
        }
      }
      } else {
        const { data } = yield call(api.get, `${API_LOCAL_URL}/fiscal/operacao`);
        if (data) {
          operacoes = [
            ...data.filter(x => {
              if (tipoDocumento === TIPO_DOCUMENTO.ORCAMENTO) {
                return x.orcamentoSerie !== 0;
              } else if (tipoDocumento === TIPO_DOCUMENTO.PEDIDO) {
                return x.pedidoSerie !== 0;
              } else if (tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA) {
                return x.operacao === 'S' && x.documentoFinalidade === 1;
              }
            })
          ];
        }
      }
  

const operacoesPermitidas = vendedorUser ? operacoes.reduce((array, operacao) => {
      if (vendedorUser?.fatEquipevenda?.operacoes.find(x => operacao.id === x.fisOperacaoId)) {
        return [...array, operacao];
      } else {
        return [...array];
      }
    }, [])
    : operacoes;

    const fisOperacaoId = venda?.fisOperacao?.id ?? venda?.fisOperacaoId;
    let fisOperacao = [
      ...(operacoesPermitidas?.length > 0 ? operacoesPermitidas : operacoes)
    ]?.find(x => x.id === fisOperacaoId);
    if (!fisOperacao) {
      fisOperacao = operacoesPermitidas[0] ?? operacoes[0];
    }

    let condicoesPagamentos = [];
    if(cacheEmpresaId === empresaId && sessionStorage.getItem('condicoesPagamentos')) {
      condicoesPagamentos = JSON.parse(sessionStorage.getItem('condicoesPagamentos'));
    } else {
      const { data: condicoesPagamentosData } = isOnline
      ? yield call(
          api.get,
          `${API_BASE_URL}/v1/consulta/condicaopagamento/terminalpreco?$select=${[
            'id',
            'descricao',
            'quantidadeParcela',
            'entradaExigida'
          ].join(',')}&$expand=${[
            'condicaoParcelas($expand=finMeiopagamento($select=id,descricao,nfePagamento,tefModalidade,tef,entraQuitado,geraCheque,disponivelEcf,desativado))',
            'condicaoEntrada($expand=finMeiopagamento($select=id,descricao,nfePagamento,tefModalidade,tef,entraQuitado,geraCheque,disponivelEcf,desativado))'
          ].join(',')}`
        )
      : yield call(api.get, `${API_LOCAL_URL}/faturamento/subrotina/condicaopagamento`);
      condicoesPagamentos = condicoesPagamentosData;
      sessionStorage.setItem('condicoesPagamentos', JSON.stringify(condicoesPagamentosData));
    }

    const matrizCondicaoPagamento = [
      ...condicoesPagamentos.filter(condicao => condicao?.condicaoParcelas?.length > 0)
    ]
      ?.reduce((array, item) => {
        const { condicaoParcelas, condicaoEntrada, ...condicaoBase } = item;

        condicaoParcelas?.forEach(parcela => {
          if (parcela?.finMeiopagamento?.desativado) {
            return;
          }
          const meioPagamento = array?.find(
            pagamento => pagamento.id == parcela.finMeiopagamentoId
          );

          const condicaoPagamento = {
            id: condicaoBase?.id,
            descricao: condicaoBase?.descricao,
            quantidadeParcela: condicaoBase?.quantidadeParcela,
            entradaExigida: condicaoBase?.entradaExigida,
            percentualAcrescimo: parcela?.percentualAcrescimo / 100,
            meiosPagamentoEntrada: condicaoEntrada
              ?.filter(x => !x?.finMeiopagamento?.desativado)
              ?.map(entrada => ({
                id: entrada?.finMeiopagamento?.id,
                descricao: entrada?.finMeiopagamento?.descricao,
                nfePagamento: entrada?.finMeiopagamento?.nfePagamento,
                tef: entrada?.finMeiopagamento?.tef,
                tefModalidade: entrada?.finMeiopagamento?.tefModalidade,
                entraQuitado: entrada?.finMeiopagamento?.entraQuitado,
                geraCheque: entrada?.finMeiopagamento?.geraCheque,
                disponivelEcf: entrada?.finMeiopagamento?.disponivelEcf,
                desativado: entrada?.finMeiopagamento?.desativado
              }))
          };

          if (meioPagamento) {
            meioPagamento?.condicoes?.push(condicaoPagamento);
          } else {
            array.push({
              id: parcela?.finMeiopagamento?.id,
              fatCondicaopagamentoId: condicaoBase?.id,
              descricao: parcela?.finMeiopagamento?.descricao,
              nfePagamento: parcela?.finMeiopagamento?.nfePagamento,
              tef: parcela?.finMeiopagamento?.tef,
              tefModalidade: parcela?.finMeiopagamento?.tefModalidade,
              entraQuitado: parcela?.finMeiopagamento?.entraQuitado,
              geraCheque: parcela?.finMeiopagamento?.geraCheque,
              disponivelEcf: parcela?.finMeiopagamento?.disponivelEcf,
              percentualAcrescimo: parcela?.percentualAcrescimo / 100,
              desativado: parcela?.finMeiopagamento?.desativado,
              condicoes: [condicaoPagamento]
            });
          }
        });
        return array;
      }, [])
      ?.filter(x => x.disponivelEcf)
      ?.sort((a, b) => a.nfePagamento - b.nfePagamento);

      let bandeiras = [];
      if(cacheEmpresaId !== empresaId) {
        const { data: bandeiras } = isOnline
        ? yield call(api.get, `${API_BASE_URL}/v1/consulta/cartaobandeira`)
        : yield call(api.get, `${API_LOCAL_URL}/faturamento/cartaobandeira`);
        sessionStorage.setItem('bandeiras', JSON.stringify(bandeiras));
      } else {
        bandeiras = JSON.parse(sessionStorage.getItem('bandeiras'));
      }
      
    let bancos = [];
    if(!sessionStorage.getItem('bancos')) {
      const { data: bancos } = isOnline
        ? yield call(api.get, `${API_BASE_URL}/v1/consulta/instituicaofinanceira`)
        : yield call(api.get, `${API_LOCAL_URL}/financeiro/instituicaofinanceira`);
        sessionStorage.setItem('bancos', JSON.stringify(bancos));
    } else {
      bancos = JSON.parse(sessionStorage.getItem('bancos'));
    }

    let serie = fisOperacao?.documentoSerie;
    if (tipoDocumento === TIPO_DOCUMENTO.ORCAMENTO) {
      serie = fisOperacao?.orcamentoSerie;
    } else if (tipoDocumento === TIPO_DOCUMENTO.PEDIDO) {
      serie = fisOperacao?.pedidoSerie;
    }

    if (terminal) {
      const operacaoPdv = fisOperacao?.operacoesPdv?.find(x => x.pdvIdentificacao === terminal);
      if (operacaoPdv) {
        serie = operacaoPdv?.serie;
      }
    }

    const multiplosPagamentos =
      (tipoDocumento === TIPO_DOCUMENTO.PEDIDO ||
        tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA) &&
      !venda?.fatCondicaopagamentoId &&
      !venda?.finMeiopagamentoId &&
      venda?.parcelas?.length > 0;

    if (isWeb) {
      numero = venda?.numero ?? venda?.documentoNumero;
    } else {
      const { data } = yield call(
        api.post,
        `${API_LOCAL_URL}/painelcontrole/autosequencia/numero`,
        {
          tipoDocumento,
          serie: tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA ? serie?.toString() : serie,
          modelo:
            tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA ? fisOperacao?.documentoModelo : null
        }
      );
      if (data) {
        numero = data?.numero;
      }
    }

    let itensVenda;
    if(tipoDocumento === TIPO_DOCUMENTO.ORCAMENTO) {
      itensVenda = (venda?.itens ?? [])?.map(item => {
        const estoques = item?.saldoEstoque?.filter(estoque => estoque?.intEmpresaId === empresaId && estoque?.estArmazem && item?.produtoEstArmazemId === estoque?.estArmazemId);
        return { 
          ...item, 
          uuid: item?.uuid ?? uuid(),
          itemEstoque: estoques?.reduce((total, est) => total + est?.estoqueReal, 0) - estoques?.reduce((total, est) => total + est?.estoqueReservado, 0)
        }
      })
    }
    
    else if((tipoDocumento === TIPO_DOCUMENTO.PEDIDO && venda?.importado)) {
      itensVenda = (venda?.itens ?? [])?.map(item => {
        const estoques = item?.saldoEstoque?.filter(estoque => estoque?.intEmpresaId === empresaId && estoque?.estArmazem && item?.produtoEstArmazemId === estoque?.estArmazemId);
        return { 
          ...item, 
          uuid: item?.uuid ?? uuid(),
          itemEstoque: estoques?.reduce((total, est) => total + est?.estoqueReal, 0) - estoques?.reduce((total, est) => total + est?.estoqueReservado, 0)
        }
      })
    }
    else if ((tipoDocumento === TIPO_DOCUMENTO.PEDIDO && venda?.editar) || (tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA && venda?.importado)) {
      itensVenda = (venda?.itens ?? [])?.map(item => {
        const itemEstoque = item?.produtoEstProduto?.saldoEstoque?.estoqueReal - item?.produtoEstProduto?.saldoEstoque?.estoqueReservado + item.itemQuantidade;
        return { 
          ...item, 
          uuid: item?.uuid ?? uuid(),
          itemEstoque
        }
      })
    }
    else {
      itensVenda = (venda?.itens ?? [])?.map(item => ({ ...item, uuid: item?.uuid ?? uuid() }));
    }

    let parcelasVenda = (venda?.parcelas ?? [])?.map(parcela => ({
      ...parcela,
      uuid: parcela?.uuid ?? uuid()
    }));

    if (tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA) {
      let parcelasTef = parcelasVenda?.filter(
        x =>
          x?.finMeiopagamento?.tef &&
          x?.finMeiopagamento?.tefModalidade !== null &&
          !x?.pagamentoEfetuado &&
          x?.documentoNumero
      );
      const multiplosPagamentosTef = parcelasTef?.length > 0;
      if (multiplosPagamentosTef) {
        yield call(signalr.invokeInicializaTef);
        yield call(signalr.invokeConfirmaPagamentoTef, numero?.toString(), false);

        // for (const parcela of parcelasTef) {
        //   const parcelaIndex = parcelasVenda?.find(x => x?.uuid === parcela?.uuid);
        //   if (parcelaIndex !== -1) {
        //     parcelasVenda[parcelaIndex].pagamentoEfetuado = false;
        //     parcelasVenda[parcelaIndex].documentoNumero = null;
        //   }
        // }
      }
    }
    
    yield put(
      pdvActions.iniciarVendaSuccess({
        ...INITIAL_STATE,
        matrizCondicaoPagamento,
        tipoDocumento,
        venda:
          tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA
            ? {
                ...(venda ? venda : INITIAL_STATE.venda),
                itens: itensVenda ?? [],
                parcelas: parcelasVenda ?? [],
                fisOperacao,
                fatVendedor,
                documentoDataEmissao: dataAtual,
                documentoDataHoraSaida: dataAtual,
                documentoSerie: serie?.toString(),
                documentoModelo: fisOperacao?.documentoModelo,
                documentoMovimento: fisOperacao?.operacao ?? 'S',
                documentoNumero: numero,
                documentoPresenca: 0,
                documentoTipo: 0,
                documentoTransmissao: 1,
                documentoFrete: 9,
                informacaoComplementar: venda?.informacaoComplementar,
                ...atualizaTotais(itensVenda ?? [])
              }
            : {
                ...(venda ? venda : INITIAL_STATE.venda),
                itens: itensVenda ?? [],
                parcelas: parcelasVenda ?? [],
                fisOperacao,
                fatVendedor,
                dataEmissao: dataAtual,
                requerMontagem: false,
                serie,
                numero,
                informacaoComplementar: venda?.informacaoComplementar,
                ...atualizaTotais(itensVenda ?? [])
              },

        selects: {
          bandeiras,
          operacoes,
          operacoesPermitidas:
            operacoesPermitidas?.length > 0 ? [...operacoesPermitidas] : [...operacoes],
          vendedores,
          bancos,
          condicoesPagamentos
        }
      })
    );
    
    //Utilizado apenas para validar a entrada, para todo o resto usa a condição de pagamento amarrado ao meio de pagamento
    const condicaoPagamentoVenda = condicoesPagamentos?.find(
      x => x?.id === venda?.fatCondicaopagamentoId
    );

    if (tipoDocumento === TIPO_DOCUMENTO.ORCAMENTO) {
      const meioPagamento = matrizCondicaoPagamento?.find(x => x.id === venda?.finMeiopagamentoId);
      yield put(pdvActions.selecionarMeioPagamento(meioPagamento));
      if (meioPagamento) {
        const condicaoPagamento = meioPagamento?.condicoes?.find(
          x => x.id === venda?.fatCondicaopagamentoId
        );
        yield put(pdvActions.selecionarCondicaoPagamento(condicaoPagamento));
      }
    } else if (tipoDocumento === TIPO_DOCUMENTO.PEDIDO) {
      if (multiplosPagamentos) {
        yield put(pdvActions.setMultiplosPagamentos(multiplosPagamentos));
      } else {
        let itemPagamento = {
          meioPagamento: null,
          meioPagamentoEntrada: null,
          condicaoPagamento: null,
          condicoes: [],
          parcelas: venda?.parcelas?.length > 0 ? [...venda?.parcelas] : [],
          valorEntrada: 0,
          valorRecebido:
            venda?.parcelas?.reduce((total, item) => (total += item?.parcelaValor), 0) ?? 0,
          valorTroco: 0,
          valorTotal: venda?.valorTotal
        };

        if (
          venda?.parcelas?.length === 1 ||
          (venda?.parcelas?.length > 1 && !condicaoPagamentoVenda?.entradaExigida) ||
          venda?.importado
        ) {
          const meioPagamento = venda?.importado
            ? matrizCondicaoPagamento?.find(x => x.id === venda?.finMeiopagamentoId)
            : matrizCondicaoPagamento?.find(x => x.id === venda?.parcelas?.[0]?.finMeiopagamentoId);

          yield put(pdvActions.selecionarMeioPagamento(meioPagamento));

          let condicaoPagamento =
            meioPagamento?.condicoes?.find(x => x.id === venda?.fatCondicaopagamentoId) ?? null;
          if (condicaoPagamento) {
            condicaoPagamento = {
              ...condicaoPagamento,
              percentualAcrescimo: meioPagamento?.percentualAcrescimo ?? 0
            };
          }

          yield put(pdvActions.selecionarCondicaoPagamento(condicaoPagamento));
          yield put(
            pdvActions.atualizarItemPagamento({
              ...itemPagamento,
              condicaoPagamento: condicaoPagamento ?? null,
              condicoes: meioPagamento?.condicoes?.length ? [...meioPagamento?.condicoes] : [],
              meioPagamento: meioPagamento ?? null
            })
          );
        } else {
          const meioPagamento = matrizCondicaoPagamento?.find(
            x => x.id === venda?.parcelas?.[1]?.finMeiopagamentoId
          );
          yield put(pdvActions.selecionarMeioPagamento(meioPagamento));
          const meioPagamentoEntrada = matrizCondicaoPagamento?.find(
            x => x.id === venda?.parcelas?.[0]?.finMeiopagamentoId
          );

          let condicaoPagamento =
            meioPagamento?.condicoes?.find(x => x.id === venda?.fatCondicaopagamentoId) ?? null;
          if (condicaoPagamento) {
            condicaoPagamento = {
              ...condicaoPagamento,
              percentualAcrescimo: meioPagamento?.percentualAcrescimo ?? 0
            };
          }

          yield put(pdvActions.selecionarCondicaoPagamento(condicaoPagamento));

          yield put(
            pdvActions.atualizarItemPagamento({
              ...itemPagamento,
              condicaoPagamento: condicaoPagamento ?? null,
              condicoes: meioPagamento?.condicoes?.length ? [...meioPagamento?.condicoes] : [],
              meioPagamento,
              meioPagamentoEntrada,
              valorEntrada: venda?.parcelas?.[0]?.parcelaValor
            })
          );
        }
      }
    } else if (tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA) {
      if (multiplosPagamentos) {
        yield put(pdvActions.setMultiplosPagamentos(multiplosPagamentos));
      } else {
        let itemPagamento = {
          meioPagamento: null,
          meioPagamentoEntrada: null,
          condicaoPagamento: null,
          condicoes: [],
          parcelas: venda?.parcelas?.length > 0 ? [...venda?.parcelas] : [],
          valorEntrada: 0,
          valorRecebido:
            venda?.parcelas?.reduce((total, item) => (total += item?.parcelaValor), 0) ?? 0,
          valorTroco: 0,
          valorTotal: venda?.valorTotal
        };

        if (
          venda?.parcelas?.length === 1 ||
          (venda?.parcelas?.length > 1 && !condicaoPagamentoVenda?.entradaExigida)
        ) {
          const meioPagamento =
            matrizCondicaoPagamento?.find(x => x.id === venda?.parcelas?.[0]?.finMeiopagamentoId) ??
            null;

          yield put(pdvActions.selecionarMeioPagamento(meioPagamento));

          let condicaoPagamento =
            meioPagamento?.condicoes?.find(x => x.id === venda?.fatCondicaopagamentoId) ?? null;
          if (condicaoPagamento) {
            condicaoPagamento = {
              ...condicaoPagamento,
              percentualAcrescimo: meioPagamento?.percentualAcrescimo ?? 0
            };
          }
          yield put(pdvActions.selecionarCondicaoPagamento(condicaoPagamento));

          yield put(
            pdvActions.atualizarItemPagamento({
              ...itemPagamento,
              condicaoPagamento: condicaoPagamento ?? null,
              condicoes: meioPagamento?.condicoes?.length ? [...meioPagamento?.condicoes] : [],
              meioPagamento: meioPagamento ?? null
            })
          );
        } else {
          const meioPagamento = matrizCondicaoPagamento?.find(
            x => x.id === venda?.parcelas?.[1]?.finMeiopagamentoId
          );
          yield put(pdvActions.selecionarMeioPagamento(meioPagamento));

          const meioPagamentoEntrada = matrizCondicaoPagamento?.find(
            x => x.id === venda?.parcelas?.[0]?.finMeiopagamentoId
          );

          let condicaoPagamento =
            meioPagamento?.condicoes?.find(x => x.id === venda?.fatCondicaopagamentoId) ?? null;
          if (condicaoPagamento) {
            condicaoPagamento = {
              ...condicaoPagamento,
              percentualAcrescimo: meioPagamento?.percentualAcrescimo ?? 0
            };
          }
          yield put(pdvActions.selecionarCondicaoPagamento(condicaoPagamento));
          yield put(
            pdvActions.atualizarItemPagamento({
              ...itemPagamento,
              condicaoPagamento: condicaoPagamento ?? null,
              condicoes: meioPagamento?.condicoes?.length ? [...meioPagamento?.condicoes] : [],
              meioPagamento,
              meioPagamentoEntrada,
              valorEntrada: venda?.parcelas?.[0]?.parcelaValor
            })
          );
        }
      }
    }

    sessionStorage.setItem('cacheEmpresaId', empresaId);

    if(confirmaLogin && parametroConfirmaLogin && tipoDocumento !== TIPO_DOCUMENTO.DOCUMENTOSAIDA) yield put(pdvActions.setShowModalTrocaUsuario({show: true, confirmLogin: true}));
    if(tipoDocumento === TIPO_DOCUMENTO.PEDIDO && exibirModalCliente && !parametroConfirmaLogin) yield put(pdvActions.setShowModalIdentificarCliente({ show: true }));
    if(obrigaMeioPagamento && tipoDocumento !== TIPO_DOCUMENTO.DOCUMENTOSAIDA && !exibirModalCliente && !parametroConfirmaLogin) {
      yield put(pdvActions.setShowModalConsultaPagamento({ show: true }));
    }

    // if (venda?.importado) {
    //   yield call(calculaImpostos);
    // }
  } catch (error) {
    yield put({ type: APP_ERROR, error });
    throw error;
  } finally {
    yield put(pdvActions.setShowModalLoadingMensagem({ show: false }));
    yield put(pdvActions.updateLoadingMensagem(''));
  }
}

export function* alterarDocumento({ data }) {
  try {
    const tipoDocumento = data;
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* alterarTipoDocumento({ tipoDocumento }) {
  try {
    if (tipoDocumento === TIPO_DOCUMENTO.ORCAMENTO) {
      toast.warning(`Tipo do documento alterado, pagamento(s) removido(s)`);
      yield put(pdvActions.setMultiplosPagamentos(false));
      yield put(pdvActions.limparPagamentos());
    }

    yield put(pdvActions.alterarTipoDocumentoSuccess(tipoDocumento));
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* identificarCliente({ cliente }) {
  try {
    const isOnline = yield select(getApplicationIsOnline());

    const { venda } = yield select(state => state.pdv);

    yield put(pdvActions.setShowModalIdentificarCliente({ show: false }));

    let { intCliente, ...rest } = cliente;
    if (intCliente?.id) {
      yield put(pdvActions.setShowModalLoadingMensagem({ show: true }));
      yield put(pdvActions.updateLoadingMensagem('Carregando cliente, aguarde...'));

      if(intCliente.celular === null) intCliente.celular = '';
      if(intCliente.telefone === null) intCliente.telefone = '';
      if(intCliente.celular !== cliente.clienteCelular || intCliente.telefone !== cliente.clienteTelefone) {
        yield call(
          api.put,
          `${API_NODE_URL}/integracao/cliente/${intCliente?.id}/contato`,
          {
            telefone: cliente.clienteTelefone,
            celular: cliente.clienteCelular
          }
        );
      }

      const { data: regraComercial } = isOnline
        ? yield call(
            api.get,
            `${API_NODE_URL}/faturamento/cliente/regra-comercial/${intCliente?.id}`
          )
        : { data: null };

      intCliente = regraComercial ? { ...intCliente, regraComercial } : intCliente;

      const { parametros } = yield select(state => state.auth);
      const parametro = getParametroAsBoolean(parametros, 'PF023');
      if (parametro) {
        const { data: creditoCliente } = isOnline
          ? yield call(api.get, `${API_NODE_URL}/creditoecobranca/cliente/${intCliente?.id}`)
          : yield call(
              api.get,
              `${API_LOCAL_URL}/integracao/cliente/creditoecobranca/${intCliente?.id}`
            );

        intCliente = {
          ...intCliente,
          creditoCliente: {
            saldoLimiteDisponivel: creditoCliente?.saldoLimiteDisponivel ?? 0,
            saldoCreditoDisponivel: creditoCliente?.saldoCreditoDisponivel ?? 0,
            clienteInadimplente: creditoCliente?.inadimplente ?? false
          },
          exibirCreditoCliente: true
        };
      }
    }

    yield put(
      pdvActions.identificarClienteSuccess({
        ...rest,
        intCliente,
        fatTabelavenda: intCliente?.fatTabelavenda,
        exibirCreditoCliente: intCliente?.exibirCreditoCliente ?? false,
        creditoCliente: intCliente?.creditoCliente ?? {
          saldoLimiteDisponivel: 0,
          saldoCreditoDisponivel: 0,
          clienteInadimplente: false
        },
        regraComercial: intCliente?.regraComercial ?? [],
        intClienteEnderecoId: intCliente?.intClienteEnderecoId ?? null,
        enderecoLogradouro: intCliente?.enderecoLogradouro,
        enderecoLogradouroNumero: intCliente?.enderecoLogradouroNumero,
        enderecoBairro: intCliente?.enderecoBairro,
        enderecoCep: intCliente?.enderecoCep,
        enderecoIntCidade: intCliente?.enderecoIntCidade,
        enderecoUf: intCliente?.enderecoUf,
        enderecoComplemento: intCliente?.enderecoComplemento
      })
    );
    yield call(calculaImpostos);


    if(intCliente?.clienteMeioPagamento?.length > 0 && intCliente?.clienteMeioPagamento && venda?.finMeiopagamento) {
      const verificaPagamento = intCliente.clienteMeioPagamento.some(pagamento => {
        if(pagamento.finMeiopagamentoId === venda.finMeiopagamento.id) return true;
      });
  
      if(!verificaPagamento) yield put(pdvActions.selecionarMeioPagamento(null));
    }
    

    if(intCliente?.clienteMeioPagamento?.length > 0 && intCliente?.clienteMeioPagamento && venda?.parcelas) {
      const idsClientePagamento = intCliente?.clienteMeioPagamento.map(p => p.finMeiopagamentoId);
      const newParcelas = venda.parcelas.filter(parcela => {
        if(idsClientePagamento.includes(parcela.finMeiopagamentoId)) {
          return parcela;
        }
      });
      yield put(pdvActions.atualizarParcelas(newParcelas));
    }


  } catch (error) {
    yield put({ type: APP_ERROR, error });
  } finally {
    yield put(pdvActions.setShowModalLoadingMensagem({ show: false }));
    yield put(pdvActions.updateLoadingMensagem(''));
  }
}

export function* selecionaAddProduto({ produto, callback, quantidade = 1 }) {
  try {

    const { venda, status } = yield select(state => state.pdv);

    if (status === STATUS_PDV.IDENTIFICACAO) {
      yield put(pdvActions.setStatus(STATUS_PDV.VENDA));
    }

    if (!produto) {
      yield call(limparProduto);
      return;
    }

    yield put(pdvActions.setShowModalConsultaProduto({ show: false }));
    yield put(pdvActions.setLoadingProduto(true));

    const promocoes = [];
    const imposto = produto?.estProdutoImposto[0];
    const produtoPrecoVenda = imposto?.precoVenda ?? 0;
    const percentualAcrescimo = venda?.fatCondicaopagamento?.percentualAcrescimo ?? 0;

    const itemValorAcrescimoCondicao = percentualAcrescimo
      ? currency(produtoPrecoVenda).multiply(percentualAcrescimo)
      : 0;

    const itemValorUnitario =
      percentualAcrescimo > 0
        ? currency(produtoPrecoVenda).add(itemValorAcrescimoCondicao)
        : produtoPrecoVenda;

    const { saldoEstoque, ...item } = produto;

    const conversores = [
      { conversor: 1, valor: item.unidadeComercial },
      ...(item.produtoConversores
        ? item.produtoConversores
            .filter(c => c.unidadeOrigem === item.unidadeComercial)
            .map(conversor => ({
              ...conversor,
              valor: conversor.unidadeDestino
            }))
        : [])
    ];
    const unidadePadraoVenda =
      conversores?.find(c => c.valor == imposto?.unidadeComercialPadrao) ?? conversores?.[0];

    const produtoEstArmazemId = imposto?.estoqueEstArmazemId ?? null;
    const produtoEstArmazem =
      imposto?.estoqueEstArmazem ?? produtoEstArmazemId
        ? item?.armazens?.find(x => x.id === produtoEstArmazemId)
        : item?.armazens?.[0];

    const itemCfop = venda?.fisOperacao?.itemCfop ?? item?.cfopSaida;

    const itemQuantidade = Number(quantidade);

    let itemVenda = {
      ...BASE_ITEM,
      uuid: uuid(),
      produtoEstProduto: { ...item, listaUnidades: conversores },
      produtoEstProdutoId: item?.id,
      produtoEstProdutoGrade: item?.estProdutoGrade ?? null,
      produtoEstProdutoGradeId:
        item?.estProdutoGrade?.length > 0 ? item.estProdutoGrade[0]?.id : null,
      produtoGradeLinha: item?.estProdutoGrade?.gradeEixoLinha ?? '',
      produtoGradeColuna: item?.estProdutoGrade?.gradeEixoColuna ?? '',
      produtoOrigem: item?.mercadoriaOrigem,
      unidadeComercialDiferenteUnidadeVenda: conversores[0].valor !== unidadePadraoVenda?.valor,
      produtoEan: item?.estProdutoGrade?.length > 0 ? item?.estProdutoGrade[0]?.ean : item.ean,
      produtoCodigoAuxiliar:
        item?.estProdutoGrade?.length > 0
          ? item?.estProdutoGrade?.[0]?.codigoAuxiliar
          : item?.codigoAuxiliar,
      produtoCodigo: item?.codigo,
      produtoDescricao: item?.descricao,
      produtoUnidadeComercial: unidadePadraoVenda?.valor,
      produtoUnidadeTributavel: item?.unidadeTributavel ?? unidadePadraoVenda?.valor,
      produtoQuantidadeTributavel: item?.quantidadeTributavel,
      produtoEanTributavel: item?.eanTributavel,
      produtoQuantidadeEmbalagem: item?.embalagemQuantidade,
      produtoEstArmazem,
      produtoEstArmazemId,
      produtoPrecoVenda,
      itemValorUnitario,
      itemQuantidade,
      itemValorAcrescimoCondicao,
      itemQuantidadexEmbalagem: Number(
        currency(item?.produtoQuantidadeEmbalagem * quantidade, {
          precision: 2
        })
      ),
      itemEstoque: saldoEstoque?.estoqueReal ?? null,
      itemCfop,
      itemTipo: item?.itemTipo,
      saldoEstoque: saldoEstoque
        ? {
            ...saldoEstoque,
            estoqueDisponivel:
              (saldoEstoque?.estoqueReal ?? 0) + (saldoEstoque?.estoqueReservado ?? 0) * -1
          }
        : null,
      promocoes,
      armazens: item?.armazens,
      fracionavel: item?.fracionavel ?? false,
      imageUrl: item?.imageUrl,
      itemValorTotal: Number(
        currency(itemValorUnitario * itemQuantidade, { precision: 2 })
          .add(itemVenda?.itemValorAcrescimo ?? 0)
          .add(itemVenda?.impostoIcmsSt ?? 0)
          .add(itemVenda?.impostoIpi ?? 0)
          .subtract(itemVenda?.itemValorDesconto ?? 0)
      )
    };

    let itemAtualizado = itemVenda;
    yield put(
      pdvActions.selecionarProdutoSuccess({
        ...itemAtualizado
      })
    );
    // yield put(pdvActions.atualizarQuantidade(itemAtualizado, quantidade));
    // yield put(pdvActions.setLoadingProduto(false));

    // ---------------------------- adicionar --------------------------------

    let { venda: vendaAdd, tipoDocumento: tipoDocumentoAdd } = yield select(state => state.pdv);

    if (itemVenda?.itemQuantidade <= 0) {
      toast.warning('Informar quantidade maior que 0 !');
      return;
    }

    if (vendaAdd?.parcelas?.length > 0) {
      // toast.warning(`Remover parcela(s) para adicionar novo(s) item(ns)!`);

      const { isConfirmed } = yield call(
        toast.confirm,
        'ATENÇÃO',
        'Deseja remover parcela(s) para adicionar novo(s) item(ns)?',
        {
          icon: 'warning',
          confirmButtonText: 'Remover',
          cancelButtonText: 'Cancelar'
        }
      );

      if (!isConfirmed) {
        return;
      }

      const itemsSelecteds = [...vendaAdd?.parcelas?.map(x => x.uuid)];
      yield put(pdvActions.removerPagamentosSuccess(itemsSelecteds));

      toast.success('Parcela(s) removida(s) com sucesso!');
    }

    const permiteEstoqueNegativo = yield select(selectorParametroAsBoolean('PF021'));

    const produtoNoCarrinho = vendaAdd?.itens?.filter(
      x =>
        x?.produtoEstProdutoId === itemVenda?.produtoEstProdutoId &&
        (itemVenda?.id === null || itemVenda?._id === null)
    );

    let estoqueDisponivel = 0;
    if(itemVenda.saldoEstoque) {
      estoqueDisponivel = (itemVenda.saldoEstoque?.estoqueReal ?? 0) - (itemVenda.saldoEstoque?.estoqueReservado ?? 0);
    } else {
      estoqueDisponivel =  (itemVenda?.estoqueReal ?? 0) - (itemVenda?.estoqueReservado ?? 0);
    }

    let itemQuantidadeTotal = Number(itemVenda?.itemQuantidade);
    if (produtoNoCarrinho.length > 0) {
      toast.warning('Produto já está lançado no documento !');
      itemQuantidadeTotal += produtoNoCarrinho?.reduce(
        (tot, item) => (tot += item.itemQuantidade),
        0
      );
    }
    
    if (!permiteEstoqueNegativo && Number(itemQuantidadeTotal) > Number(estoqueDisponivel) && tipoDocumentoAdd !== TIPO_DOCUMENTO.ORCAMENTO) {
      return toast.warning(
        'De acordo com o parâmetro PF021, não é permitida a venda com estoque insuficiente!'
      );
    }

    if(itemVenda?.saldoEstoque?.estoqueDisponivel && itemVenda?.itemQuantidade > itemVenda?.saldoEstoque?.estoqueDisponivel) {
      toast.warning(`Produto ${itemVenda?.produtoDescricao} com estoque insuficiente!`);

    }

    if (itemVenda?.estoqueDisponivel && itemVenda?.itemQuantidade > itemVenda?.estoqueDisponivel) {
      toast.warning(`Produto ${itemVenda?.produtoDescricao} com estoque insuficiente!`);
    }

    if(itemVenda.itemValorAcrescimo > 0) {
      let valorTotaldoItem = itemVenda.itemValorTotal;
      itemVenda.itemValorTotal = Math.round((Number(valorTotaldoItem + itemVenda.itemValorAcrescimo) + Number.EPSILON) * 100) / 100;
      itemVenda.itemValorAcrescimo = itemVenda.itemValorAcrescimo;
    }

    itemAtualizado = itemVenda;

    const oldItemVenda = vendaAdd?.itens?.find(x => x.uuid === itemVenda.uuid) ?? null;
    if (oldItemVenda !== null) {
      yield put(
        pdvActions.atualizarItem({
          ...itemAtualizado
        })
      );
    } else {
      yield put(
        pdvActions.adicionarItemSuccess({
          ...itemAtualizado,
          itemOrdem: vendaAdd?.itens?.length + 1
        })
      );
    }

    yield call(limparProduto);
    yield put(pdvActions.setStatus(STATUS_PDV.IDENTIFICACAO));

    setTimeout(() => {
      let messageBody = document.querySelector('#TableContainer');
      messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }, 500);
    
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  } finally {
    yield put(pdvActions.setLoadingProduto(false));
  }
}

export function* selecionarProduto({ produto, callback, quantidade = 1 }) {
  try {
    const { venda, status } = yield select(state => state.pdv);
    const isOnline = yield select(getApplicationIsOnline());
    if (status === STATUS_PDV.IDENTIFICACAO) {
      yield put(pdvActions.setStatus(STATUS_PDV.VENDA));
    }

    if (!produto) {
      yield call(limparProduto);
      return;
    }

    yield put(pdvActions.setShowModalConsultaProduto({ show: false }));
    yield put(pdvActions.setLoadingProduto(true));

    /*
    const {
      data: { items }
    } = isOnline
      ? yield call(
          api.get,
          `${API_BASE_URL}/v1/faturamento/regradesconto/?$select=${[
            'id',
            'descricao',
            'validadeInicioData',
            'validadeFimData',
            'validadeDia'
          ].join(',')}&$expand=${[
            'itens($expand=estProduto($select=id,descricao),estProdutoGrupo($select=id,descricao))'
          ].join(',')}&$filter=(${[
            '( intClienteId eq null and intClienteGrupoId eq null )'
            // `( fatVendedorId eq null or fatVendedorId eq ${vendedor?.id} )`,
            // `( date(validadeInicioData) ge ${dataBase} and date(validadeFimData) le ${dataBase} )`
          ].join('and')})`
        )
      : { data: { items: [] } };
  
    const promocoes = items
      .reduce((array, desconto) => {
        return [
          ...array,
          ...desconto.itens
            .filter(
              item =>
                item.estProdutoGrupoId === 8 ||
                item.estProdutoGrupoId === null ||
                item.estProdutoId === 1 ||
                item.estProdutoId === null
            )
            .map(item => {
              const descontoValor =
                item?.descontoValor ??
                produtoPrecoVenda - (produtoPrecoVenda * (item?.descontoPercentual ?? 0)) / 100;

              return {
                ...item,
                descricao: desconto.descricao,
                descontoValor,
                descontoPercentual:
                  (item?.descontoPercentual ??
                    ((produtoPrecoVenda - descontoValor) / produtoPrecoVenda) * 100) * -1
              };
            })
        ];
      }, [])
      .sort((a, b) => a.descontoValor - b.descontoValor);
      */

    const promocoes = [];
    const imposto = produto?.estProdutoImposto[0];
    const produtoPrecoVenda = imposto?.precoVenda ?? 0;
    const percentualAcrescimo = venda?.fatCondicaopagamento?.percentualAcrescimo ?? 0;

    const itemValorAcrescimoCondicao = percentualAcrescimo
      ? currency(produtoPrecoVenda).multiply(percentualAcrescimo)
      : 0;

    const itemValorUnitario =
      percentualAcrescimo > 0
        ? currency(produtoPrecoVenda).add(itemValorAcrescimoCondicao)
        : produtoPrecoVenda;

    const { saldoEstoque, ...item } = produto;

    // Unidade de venda e conversores
    const conversores = [
      { conversor: 1, valor: item.unidadeComercial },
      ...(item.produtoConversores
        ? item.produtoConversores
            .filter(c => c.unidadeOrigem === item.unidadeComercial)
            .map(conversor => ({
              ...conversor,
              valor: conversor.unidadeDestino
            }))
        : [])
    ];
    const unidadePadraoVenda =
      conversores?.find(c => c.valor == imposto?.unidadeComercialPadrao) ?? conversores?.[0];

    const produtoEstArmazemId = imposto?.estoqueEstArmazemId ?? null;
    const produtoEstArmazem =
      imposto?.estoqueEstArmazem ?? produtoEstArmazemId
        ? item?.armazens?.find(x => x.id === produtoEstArmazemId)
        : item?.armazens?.[0];

    const itemCfop = venda?.fisOperacao?.itemCfop ?? item?.cfopSaida;

    const produtoMultiplo = produto?.embalagemMultiplo === true;

    if (produtoMultiplo) {
      quantidade = produto.embalagemQuantidade;
    }
    const itemVenda = {
      ...BASE_ITEM,
      uuid: uuid(),
      produtoEstProduto: { ...item, listaUnidades: conversores },
      produtoEstProdutoId: item?.id,
      produtoEstProdutoGrade: item?.estProdutoGrade ?? null,
      produtoEstProdutoGradeId:
        item?.estProdutoGrade?.length > 0 ? item.estProdutoGrade[0]?.id : null,
      produtoGradeLinha: item?.estProdutoGrade?.gradeEixoLinha ?? '',
      produtoGradeColuna: item?.estProdutoGrade?.gradeEixoColuna ?? '',
      produtoOrigem: item?.mercadoriaOrigem,
      unidadeComercialDiferenteUnidadeVenda: conversores[0].valor !== unidadePadraoVenda?.valor,
      produtoEan: item?.estProdutoGrade?.length > 0 ? item?.estProdutoGrade[0]?.ean : item.ean,
      produtoCodigoAuxiliar:
        item?.estProdutoGrade?.length > 0
          ? item?.estProdutoGrade?.[0]?.codigoAuxiliar
          : item?.codigoAuxiliar,
      produtoCodigo: item?.codigo,
      produtoDescricao: item?.descricao,
      produtoUnidadeComercial: unidadePadraoVenda?.valor,
      produtoUnidadeTributavel: item?.unidadeTributavel ?? unidadePadraoVenda?.valor,
      produtoQuantidadeTributavel: item?.quantidadeTributavel,
      produtoEanTributavel: item?.eanTributavel,
      produtoQuantidadeEmbalagem: item?.embalagemQuantidade,
      produtoEstArmazem,
      produtoEstArmazemId,
      produtoPrecoVenda,
      itemValorUnitario,
      itemValorAcrescimoCondicao,
      itemQuantidadexEmbalagem: item?.embalagemQuantidade * 1,
      itemEstoque: saldoEstoque?.estoqueReal + (saldoEstoque?.estoqueReservado * -1),
      itemCfop,
      itemTipo: item?.itemTipo,
      saldoEstoque: saldoEstoque
        ? {
            ...saldoEstoque,
            estoqueDisponivel:
              (saldoEstoque?.estoqueReal ?? 0) + (saldoEstoque?.estoqueReservado ?? 0) * -1
          }
        : null,
      promocoes,
      armazens: item?.armazens,
      fracionavel: item?.fracionavel ?? false,
      imageUrl: item?.imageUrl
    };

    // Carregar lote caso exista
    // Validar armazem

    //TODO: Comentado para teste de performace
    // #BEGIN
    // const itensCalculados = yield call(calculaImpostos, itemVenda);
    // if (!itensCalculados) {
    //   yield put(pdvActions.setLoadingProduto(false));
    //   return;
    // }
    // const itemAtualizado = itensCalculados[0];
    // #END
    const itemAtualizado = itemVenda;
    yield put(
      pdvActions.selecionarProdutoSuccess({
        ...itemAtualizado,
        itemQuantidade: quantidade
        // ...Object.assign(itemVenda, itemAtualizado),
        // itemValorTotal: calculaValorTotalItem(itemAtualizado)
      })
    );
    yield put(pdvActions.atualizarQuantidade(itemAtualizado, quantidade));
    yield put(pdvActions.setLoadingProduto(false));

    // if (!isOnline) yield call(calculaImpostos);

    if (callback) yield call(callback);
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  } finally {
    yield put(pdvActions.setLoadingProduto(false));
  }
}
export function* selecionarProfissional({ profissional: fatProfissional }) {
  try {
    const pdv = yield select(state => state?.pdv);
    yield put(
      pdvActions.iniciarVendaSuccess({
        ...pdv,
        venda: { ...pdv?.venda, fatProfissional }
      })
    );
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* selecionarVendedor({ vendedor: fatVendedor }) {
  try {
    const pdv = yield select(state => state?.pdv);
    const { terminal } = yield select(state => state?.app);

    const podeAlterarVendedor = yield select(selectorRotina('F050FAT'));
    if(podeAlterarVendedor) return toast.warning('Não é possível alterar o vendedor devido a rotina F050FAT!'); 

    const operacoes = [...pdv?.selects?.operacoes];
    const operacoesPermitidas = operacoes.reduce((array, operacao) => {
      if (fatVendedor?.fatEquipevenda?.operacoes.find(x => operacao.id === x.fisOperacaoId)) {
        return [...array, operacao];
      } else {
        return [...array];
      }
    }, []);

    //TODO: validar se a operacao anterior é valida, senao pega a primeira da lista
    const fisOperacao = operacoesPermitidas[0] ?? operacoes[0];
    let serie = fisOperacao?.documentoSerie;
    if (pdv?.tipoDocumento === TIPO_DOCUMENTO.ORCAMENTO) {
      serie = fisOperacao?.orcamentoSerie;
    } else if (pdv?.tipoDocumento === TIPO_DOCUMENTO.PEDIDO) {
      serie = fisOperacao?.pedidoSerie;
    }

    if (terminal) {
      const operacaoPdv = fisOperacao?.operacoesPdv?.find(x => x.pdvIdentificacao === terminal);
      if (operacaoPdv) {
        serie = operacaoPdv?.serie;
      }
    }

    yield put(
      pdvActions.iniciarVendaSuccess({
        ...pdv,
        venda:
          pdv?.tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA
            ? {
                ...pdv?.venda,
                fisOperacao,
                fatVendedor,
                documentoSerie: serie?.toString(),
                documentoModelo: fisOperacao?.documentoModelo,
                documentoMovimento: fisOperacao?.operacao,
                documentoPresenca: fisOperacao?.documentoPresenca
              }
            : {
                ...pdv?.venda,
                fisOperacao,
                fatVendedor,
                serie
              },
        selects: {
          ...pdv.selects,
          // ao selecionar o vendedor exibir operacoesPermitidas vinculadas ao vendedor
          // operacoesPermitidas:
          //   operacoesPermitidas?.length > 0 ? [...operacoesPermitidas] : [...operacoes]
        }
      })
    );
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* selecionarMeiopagamento({ meioPagamento }) {
  try {
    if (!meioPagamento) {
      yield put(pdvActions.selecionarMeioPagamentoSuccess(null));
      yield put(
        pdvActions.atualizarItemPagamento({
          meioPagamento: null,
          condicoes: []
        })
      );
      return;
    }

    const { condicoes, ...meioPagamentoSelecionado } = meioPagamento;
      const condicoesCalculadas = yield call(montarCondicaoPagamento, {
        ...meioPagamento,
        condicoes
      });
      const { itemPagamento } = yield select(state => state.pdv);
  
      yield put(
        pdvActions.atualizarItemPagamento({
          ...itemPagamento,
          meioPagamento: meioPagamentoSelecionado,
          condicoes: [...condicoesCalculadas],
          parcelas: [],
          valorEntrada: 0,
          valorRecebido: 0,
          valorTroco: 0
        })
      );
    yield put(pdvActions.selecionarMeioPagamentoSuccess(meioPagamentoSelecionado));
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* selecionarCondicaoPagamento({ condicaoPagamento, callback }) {
  try {
    if (!condicaoPagamento) {
      yield put(pdvActions.selecionarCondicaoPagamentoSuccess(null));
      yield put(
        pdvActions.atualizarItemPagamento({
          condicaoPagamento: null
        })
      );
      return;
    }

    yield put(pdvActions.setLoadingPagamento(true));

    const { venda, itemPagamento, multiplosPagamentos } = yield select(state => state.pdv);
    const percentualAcrescimo = condicaoPagamento?.percentualAcrescimo ?? 0;
    if (
      venda?.fatCondicaopagamento?.id !== condicaoPagamento?.id &&
      venda?.valorTotal > 0 && !multiplosPagamentos
    ) {
      yield put(
        pdvActions.atualizarItens(
          venda.itens.map(item => {
            const produtoPrecoVenda = item.produtoPrecoVenda ?? 0;

            const itemValorAcrescimoCondicao = percentualAcrescimo
              ? currency(produtoPrecoVenda).multiply(percentualAcrescimo)
              : 0;

            const itemValorUnitario =
              percentualAcrescimo > 0
                ? currency(produtoPrecoVenda).add(itemValorAcrescimoCondicao)
                : produtoPrecoVenda;

            const itemValorTotal = currency(itemValorUnitario).multiply(item.itemQuantidade);

            if(item.edicaoManual) {
              return {
                ...item
              }
            }

            return {
              ...item,
              itemValorUnitario,
              itemValorAcrescimoCondicao,
              percentualAcrescimo,
              itemValorTotal
            };
          })
        )
      );
      // toast.warning(
      //   'A condição de pagamento foi alterada e os preços podem sofrer modificações!'
      // );
    }

    const { valorTotal: valorTotalAtualizado } = yield select(state => state?.pdv?.venda);
    yield put(pdvActions.selecionarCondicaoPagamentoSuccess(condicaoPagamento));

      const parcelas = yield call(montarParcelas, {
        ...itemPagamento,
        condicaoPagamento,
        valorTotalDocumento: valorTotalAtualizado,
        valorEntrada: 0
      });

      const valorRecebido = parcelas?.reduce(
        (valorTotal, parcela) => (valorTotal += parcela.parcelaValorTotal),
        0
      );
  
      yield put(
        pdvActions.atualizarItemPagamento({
          ...itemPagamento,
          condicaoPagamento,
          valorEntrada: condicaoPagamento?.entradaExigida ? parcelas[0]?.parcelaValor : 0,
          valorRecebido,
          valorTotal: valorTotalAtualizado,
          valorTroco: 0,
          parcelas: []
          //parcelas: parcelas ? [...parcelas] : []
        })
      );

    yield put(pdvActions.setLoadingPagamento(false));

    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(pdvActions.setLoadingPagamento(false));
    yield put({ type: APP_ERROR, error });
  }
}

export function* selecionarOperacao({ operacao: fisOperacao }) {
  try {
    // yield put(pdvActions.selecionarOperacaoSuccess(fisOperacao));

    const { tipoDocumento } = yield select(state => state.pdv);
    const { terminal } = yield select(state => state.app);

    let serie = fisOperacao?.documentoSerie;
    if (tipoDocumento === TIPO_DOCUMENTO.ORCAMENTO) {
      serie = fisOperacao?.orcamentoSerie;
    } else if (tipoDocumento === TIPO_DOCUMENTO.PEDIDO) {
      serie = fisOperacao?.pedidoSerie;
    }

    if (terminal) {
      const operacaoPdv = fisOperacao?.operacoesPdv?.find(x => x.pdvIdentificacao === terminal);
      if (operacaoPdv) {
        serie = operacaoPdv.serie;
      }
    }

    let numero = null;
    if (!isWeb) {
      const { data } = yield call(
        api.post,
        `${API_LOCAL_URL}/painelcontrole/autosequencia/numero`,
        {
          tipoDocumento: tipoDocumento,
          serie: tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA ? serie?.toString() : serie,
          modelo:
            tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA ? fisOperacao?.documentoModelo : null
        }
      );
      if (data) {
        numero = data?.numero;
      }
    }

    yield put(pdvActions.setShowModalConsultaOperacao({ show: false }));

    const pdv = yield select(state => state.pdv);
    yield put(
      pdvActions.iniciarVendaSuccess({
        ...pdv,
        venda:
          pdv?.tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA
            ? {
                ...pdv?.venda,
                fisOperacao,
                documentoSerie: serie,
                documentoModelo: fisOperacao?.documentoModelo,
                documentoMovimento: fisOperacao?.operacao,
                documentoPresenca: fisOperacao?.documentoPresenca,
                documentoNumero: numero
              }
            : {
                ...pdv?.venda,
                fisOperacao,
                serie,
                numero
              }
      })
    );

    yield call(calculaImpostos);
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* montarCondicaoPagamento(meioPagamento) {
  try {
    const { venda, itemPagamento } = yield select(state => state.pdv);

    if (venda?.valorTotal == 0 || venda.tipoDocumento === TIPO_DOCUMENTO.ORCAMENTO) {
      return [...meioPagamento?.condicoes];
    }

    return meioPagamento?.condicoes?.map(condicao => {
      const valorTotal = currency(venda?.valorTotal ?? 0).add(
        currency(venda?.valorTotal ?? 0).multiply(condicao?.percentualAcrescimo ?? 0)
      );

      const valorParcelamento = currency(valorTotal).subtract(itemPagamento?.valorEntrada ?? 0);

      const valorParcela = currency(valorParcelamento).divide(condicao?.quantidadeParcela);

      return {
        ...condicao,
        valorTotal,
        valorParcela
      };
    });
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* adicionarItem({ itemVenda, callback }) {
  try {
    const { venda, tipoDocumento } = yield select(state => state.pdv);
    const { empresa } = yield select(state => state.auth);

    if (itemVenda?.itemQuantidade <= 0) {
      // toast.warning('Informar quantidade maior que 0 !');
      toast.warning(`Informar quantidade Igual ou maior que ${itemVenda.produtoQuantidadeEmbalagem} !`);
      return;
    }

    let AdicionadoProdutoDeremovido = false;

    if (venda?.parcelas?.length > 0) {
      // toast.warning(`Remover parcela(s) para adicionar novo(s) item(ns)!`);

      const { isConfirmed } = yield call(
        toast.confirm,
        'ATENÇÃO',
        'Deseja remover parcela(s) para adicionar novo(s) item(ns)?',
        {
          icon: 'warning',
          confirmButtonText: 'Remover',
          cancelButtonText: 'Cancelar'
        }
      );

      if (!isConfirmed) {
        return;
      }

      AdicionadoProdutoDeremovido = true;
      const itemsSelecteds = [...venda?.parcelas?.map(x => x.uuid)];
      yield put(pdvActions.removerPagamentosSuccess(itemsSelecteds));

      toast.success('Parcela(s) removida(s) com sucesso!');
    }

    const permiteEstoqueNegativo = yield select(selectorParametroAsBoolean('PF021'));

    const produtoNoCarrinho = venda?.itens?.filter(
      x =>
        x?.produtoEstProdutoId === itemVenda?.produtoEstProdutoId &&
        (itemVenda?.id === null || itemVenda?._id === null)
    );

    let estoqueDisponivel = 0;
    let ignoraProdutoNoCarrinho = false;
    if(itemVenda.saldoEstoque) {
      if(itemVenda?.saldoEstoque && Array.isArray(itemVenda?.saldoEstoque)) {
      const res = itemVenda?.saldoEstoque?.filter(estoque => estoque.intEmpresaId === empresa.id);
      estoqueDisponivel = res?.reduce((tot, item) => (tot += item.estoqueReal), 0) - res?.reduce((tot, item) => (tot += item.estoqueReservado), 0);
    } else if (itemVenda?.saldoEstoque && !Array.isArray(itemVenda?.saldoEstoque)) {
      estoqueDisponivel = (itemVenda.saldoEstoque?.estoqueReal ?? 0) - (itemVenda.saldoEstoque?.estoqueReservado ?? 0) + produtoNoCarrinho?.filter(x => ((x.id || x?.itemImportado)))?.reduce((valorTotal, item) => (valorTotal += item.itemQuantidade), 0);
    }
    // } else if(itemVenda.saldoEstoque) {
      // estoqueDisponivel =  (itemVenda?.estoqueReal ?? 0) - (itemVenda?.estoqueReservado ?? 0);
    } else if (itemVenda.produtoEstProduto.saldoEstoque) {
      estoqueDisponivel = (itemVenda?.produtoEstProduto?.saldoEstoque?.estoqueReal ?? 0) - (itemVenda?.produtoEstProduto?.saldoEstoque?.estoqueReservado ?? 0) + produtoNoCarrinho?.filter(x => x.id || x?.itemImportado)?.reduce((valorTotal, item) => (valorTotal += item.itemQuantidade), 0);
      ignoraProdutoNoCarrinho = true;
    }

    let itemQuantidadeTotal = Number(itemVenda?.itemQuantidade);
    if (produtoNoCarrinho.length > 0 && !AdicionadoProdutoDeremovido && !ignoraProdutoNoCarrinho) {
      if(!itemVenda?.editado)toast.warning('Produto já está lançado no documento !');
      const p = produtoNoCarrinho.filter(produto => produto.uuid !== itemVenda.uuid);
      if(itemVenda.editado) {
        if(p.length > 0)
        itemQuantidadeTotal += p.produtoNoCarrinho?.reduce(
          (tot, item) => (tot += item.itemQuantidade),
          0
        );
      } else {
        itemQuantidadeTotal += produtoNoCarrinho?.reduce(
          (tot, item) => (tot += item.itemQuantidade),
          0
        );
      }
    }
    
  if (!permiteEstoqueNegativo && (Number(itemQuantidadeTotal) > Number(estoqueDisponivel)) && (tipoDocumento !== TIPO_DOCUMENTO.ORCAMENTO)) {
      return toast.warning(
        'De acordo com o parâmetro PF021, não é permitida a venda com estoque insuficiente!'
      );
    } else {
      itemVenda.estoqueInsuficiente = false;
    }

    if(itemVenda?.saldoEstoque?.estoqueDisponivel && itemVenda?.itemQuantidade > itemVenda?.saldoEstoque?.estoqueDisponivel) {
      toast.warning(`Produto ${itemVenda?.produtoDescricao} com estoque insuficiente!`);

    }

    if (itemVenda?.estoqueDisponivel && itemVenda?.itemQuantidade > itemVenda?.estoqueDisponivel) {
      toast.warning(`Produto ${itemVenda?.produtoDescricao} com estoque insuficiente!`);
    }

    //TODO: Comentado teste de performace
    // const itensCalculados = yield call(calculaImpostos, itemVenda);
    // const itemAtualizado = itensCalculados[0];

    if(itemVenda.itemValorAcrescimo > 0) {
      let valorTotaldoItem = itemVenda.itemValorTotal;
      itemVenda.itemValorTotal = Math.round((Number(valorTotaldoItem + itemVenda.itemValorAcrescimo) + Number.EPSILON) * 100) / 100;
      itemVenda.itemValorAcrescimo = itemVenda.itemValorAcrescimo;
    }

    const itemAtualizado = itemVenda;

    const oldItemVenda = venda?.itens?.find(x => x.uuid === itemVenda.uuid) ?? null;
    if (oldItemVenda !== null) {
      yield put(
        pdvActions.atualizarItem({
          ...itemAtualizado
          // ...Object.assign(itemVenda, itemAtualizado),
          // itemValorTotal: calculaValorTotalItem(itemAtualizado)
        })
      );
    } else {
      yield put(
        pdvActions.adicionarItemSuccess({
          ...itemAtualizado,
          // ...Object.assign(itemVenda, itemAtualizado),
          // itemValorTotal: calculaValorTotalItem(itemAtualizado),
          itemOrdem: venda?.itens?.length + 1
        })
      );
    }

    yield call(limparProduto);
    yield put(pdvActions.setStatus(STATUS_PDV.IDENTIFICACAO));

    // rolar scrollbar para o final
    setTimeout(() => {
      let messageBody = document.querySelector('#TableContainer');
      messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }, 500);

    if (callback) yield call(callback);
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

function* limparProduto() {
  const { itemVenda: oldItemVenda } = yield select(state => state.pdv);
  yield put(
    pdvActions.selecionarProdutoSuccess({
      ...oldItemVenda,
      produtoEstProduto: null
    })
  );
}

export function* removerItem({ itemVenda }) {
  try {
    const { isConfirmed } = yield call(
      toast.confirm,
      'ATENÇÃO',
      'Deseja excluir este registro?</br></br>Certifique-se de que realmente deseja fazer isso, pois esta ação é irreversível.',
      {
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar'
      }
    );

    if (isConfirmed) {
      yield put(pdvActions.removerItemSuccess(itemVenda?.uuid));
      toast.success('Registro removido com sucesso!');
    }
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* cancelarItem({ itemVenda, exigeConfirmacao }) {
  try {
    if (exigeConfirmacao) {
      const { isConfirmed } = yield call(
        toast.confirm,
        'ATENÇÃO',
        'Deseja cancelar este registro?',
        {
          icon: 'warning',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar'
        }
      );

      if (isConfirmed) {
        toast.success('Registro cancelado com sucesso!');

        yield put(pdvActions.cancelarItemSuccess(itemVenda?.id));
      }
    } else {
      yield put(pdvActions.cancelarItemSuccess(itemVenda?.id));
    }
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* editarQuantidadeItemVendido({payload: { quantidadeAtualizada, ...itemVenda }, callback}) {
  const toastInstance = toast.loading('Aguarde...');
  try {
    const { venda } = yield select(state => state.pdv);
    if (!quantidadeAtualizada) {
      return toast.warning('Por favor informe a quantidade do item');
    }

    const { data } = yield call(
      api.get,
      `${API_NODE_URL}/faturamento/documento-saida/produto-grade-venda?produto=${itemVenda?.produtoCodigo}`
    );

    const produtoAtualizado = data?.[0];
    const imposto = produtoAtualizado?.estProdutoImposto?.[0];

    const produtoPrecoVenda = imposto?.precoVenda ?? 0;

    const percentualAcrescimo = venda?.fatCondicaopagamento?.percentualAcrescimo ?? 0;

    const itemValorAcrescimoCondicao = percentualAcrescimo
      ? currency(produtoPrecoVenda).multiply(percentualAcrescimo)
      : 0;


    const itemValorUnitario =
      percentualAcrescimo > 0
        ? currency(itemVenda?.itemValorUnitario || produtoPrecoVenda)
        : itemVenda?.itemValorUnitario || produtoPrecoVenda;

    let itemValorTotal = Number(
      currency(itemValorUnitario * quantidadeAtualizada, { precision: 2 })
        .add(itemVenda?.impostoIcmsSt ?? 0)
        .add(itemVenda?.impostoIpi ?? 0)
    );

    const quantidadexembalagem = Number(
      currency(itemVenda.produtoQuantidadeEmbalagem * quantidadeAtualizada, {
        precision: 2
      })
    );

    // let valorAcrescentado = 0;
    // if(itemVenda.itemValorAcrescimo) {
    //   itemValorTotal = itemValorTotal + (itemValorTotal * itemVenda.itemValorAcrescimo);
    //   itemValorTotal = Math.round((Number(itemValorTotal) + Number.EPSILON) * 100) / 100;
    //   valorAcrescentado = itemValorTotal * itemVenda.itemValorAcrescimo;
    //   valorAcrescentado = Math.round((Number(valorAcrescentado) + Number.EPSILON) * 100) / 100;
    // }

    console.log({
      quantidadeAtualizada,
      quantidadexembalagem,
      itemValorUnitario,
      itemValorTotal,
      itemValorAcrescimoCondicao
    });

    const itemAtualizado = {
      uuid: uuid(),
      ...BASE_ITEM,
      produtoEstProduto: produtoAtualizado,
      produtoEstProdutoId: produtoAtualizado?.id,
      produtoEstProdutoGrade: produtoAtualizado?.estProdutoGrade ?? null,
      produtoEstProdutoGradeId:
        produtoAtualizado?.estProdutoGrade?.length > 0
          ? produtoAtualizado?.estProdutoGrade[0]?.id
          : null,
      produtoGradeLinha: produtoAtualizado?.estProdutoGrade?.gradeEixoLinha ?? '',
      produtoGradeColuna: produtoAtualizado?.estProdutoGrade?.gradeEixoColuna ?? '',
      produtoCodigo: produtoAtualizado?.codigo,
      produtoDescricao: produtoAtualizado?.descricao,
      produtoUnidadeComercial: produtoAtualizado?.unidadeComercial,
      produtoUnidadeTributavel: produtoAtualizado?.unidadeTributavel,
      produtoQuantidadeTributavel: produtoAtualizado?.quantidadeTributavel,
      produtoEanTributavel: produtoAtualizado?.eanTributavel,
      produtoQuantidadeEmbalagem: produtoAtualizado?.embalagemQuantidade,
      produtoPrecoVenda,
      produtoEstArmazem: imposto?.estoqueEstArmazem,
      produtoEstArmazemId: imposto?.estoqueEstArmazemId,
      produtoEan: produtoAtualizado?.ean,
      produtoCodigoAuxiliar: produtoAtualizado?.codigoAuxiliar,
      produtoOrigem: produtoAtualizado?.mercadoriaOrigem,
      estProdutoImposto: produtoAtualizado?.estProdutoImposto,
      itemCfop: venda?.fisOperacao ? venda?.fisOperacao?.itemCfop : produtoAtualizado?.cfopSaida,
      quantidadexembalagem,
      itemValorUnitario,
      itemValorTotal,
      itemValorAcrescimo: itemVenda.itemValorAcrescimo,
      itemValorAcrescimoCondicao,
      itemQuantidade: quantidadeAtualizada,
      itemTipo: produtoAtualizado?.itemTipo || null,
      itemEstoque: produtoAtualizado?.saldoEstoque?.estoqueReal || 0,
      estoqueReal: produtoAtualizado?.saldoEstoque?.estoqueReal || 0,
      estoqueReservado: produtoAtualizado?.saldoEstoque?.estoqueReservado || 0,
      estoqueFuturo: produtoAtualizado?.saldoEstoque?.estoqueFuturo || 0,
      estoqueDisponivel: produtoAtualizado?.saldoEstoque?.estoqueReal
        ? produtoAtualizado?.saldoEstoque?.estoqueReal +
          produtoAtualizado?.saldoEstoque?.estoqueReservado * -1
        : 0,
      fracionavel: produtoAtualizado?.fracionavel,
      permiteCustomizar: imposto?.permiteCustomizar || false
    };

    yield put(pdvActions.cancelarItem(itemVenda, false));
    yield put(pdvActions.adicionarItem(itemAtualizado));

    if (callback) {
      callback();
    }
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  } finally {
    toastInstance.close();
  }
}

export function* atualizarQuantidade({ itemVenda, quantidade }) {
  try {
    const itemQuantidade = Number(quantidade);

    // if (itemQuantidade <= 0) {
    //   return;
    // }

    // if (!itemVenda?.fracionavel && !Number.isInteger(itemQuantidade)) {
    //   return;
    // }

    const itemValorUnitario = Number(currency(itemVenda?.itemValorUnitario, { precision: 2 }));

    const itemValorTotal = Number(
      currency(itemValorUnitario * itemQuantidade, { precision: 2 })
        .add(itemVenda?.itemValorAcrescimo ?? 0)
        .add(itemVenda?.impostoIcmsSt ?? 0)
        .add(itemVenda?.impostoIpi ?? 0)
        .subtract(itemVenda?.itemValorDesconto ?? 0)
    );

    const quantidadexembalagem = Number(
      currency(itemVenda.produtoQuantidadeEmbalagem * quantidade, {
        precision: 2
      })
    );

    yield put(
      pdvActions.atualizarQuantidadeSuccess({
        ...itemVenda,
        itemQuantidade,
        quantidadexembalagem,
        itemValorUnitario,
        itemValorTotal
      })
    );
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* atualizarPrecoVendaProduto({ itemVenda, precoVenda }) {
  try {
    const quantidade = itemVenda?.itemQuantidade ?? 1;

    yield put(
      pdvActions.atualizarQuantidade({ ...itemVenda, itemValorUnitario: precoVenda }, quantidade)
    );
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* salvarEnderecoCliente({ endereco }) {
  try {
    const { venda } = yield select(state => state.pdv);

    if (venda?.intCliente?.id === 0) {
      return;
    }

    const { data } =
      endereco?.id > 0
        ? yield call(
            api.put,
            `${API_NODE_URL}/integracao/cliente/${venda?.intCliente?.id}/enderecos/${endereco?.id}`,
            {
              ...endereco
            }
          )
        : yield call(
            api.post,
            `${API_NODE_URL}/integracao/cliente/${venda?.intCliente?.id}/enderecos`,
            {
              ...endereco
            }
          );
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* adicionarParcelas({ itemPagamento }) {
  try {
    const { venda } = yield select(state => state.pdv);

    yield put(pdvActions.adicionarParcelasSuccess(itemPagamento));
    yield put(pdvActions.setShowModalMultiploPagamento({ show: false }));

    const valorParcelas =
      [...(venda?.parcelas ?? []), ...itemPagamento?.parcelas]?.reduce(
        (total, item) => (total += item?.parcelaValor),
        0
      ) ?? 0;

    const valorSaldoPagar = Number(currency(venda?.valorTotal).subtract(valorParcelas));

    if (valorSaldoPagar === 0 && !itemPagamento.naoFechaVenda) {
      yield put(pdvActions.fecharVenda());
    }
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* removerPagamentos({ itemsSelecteds }) {
  try {
    const { isConfirmed } = yield call(
      toast.confirm,
      'ATENÇÃO',
      'Deseje estonar os pagamentos selecionados?</br></br>Certifique-se de que realmente deseja fazer isso, pois esta ação é irreversível.',
      {
        icon: 'warning',
        confirmButtonText: 'Estornar pagamento(s)',
        cancelButtonText: 'Cancelar'
      }
    );

    if (isConfirmed) {
      yield put(pdvActions.removerPagamentosSuccess(itemsSelecteds));
      toast.success('Pagamento(s) estornado(s) com sucesso!');

      const { parcelas } = yield select(state => state.pdv.venda);
      if (parcelas?.length === 0) {
        yield put(pdvActions.setShowModalListaPagamentos({ show: false }));
      }
    }
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* adicionarPagamento() {
  try {
    const { itemPagamento } = yield select(state => state.pdv);

    yield put(pdvActions.adicionarPagamentoSuccess(itemPagamento));
    yield put(pdvActions.fecharVenda());
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* selecionarOutroPagamento() {
  try {
    yield put(pdvActions.adicionarPagamentoSuccess(itemPagamento));
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* atualizarValorEntrada({ valorEntrada }) {
  try {
    const { venda, itemPagamento } = yield select(state => state.pdv);
    const valorTotalDocumento = venda?.valorTotal;

    const parcelas = yield call(montarParcelas, {
      ...itemPagamento,
      valorTotalDocumento,
      valorEntrada
    });

    const valorRecebido = parcelas?.reduce(
      (valorTotal, parcela) => (valorTotal += parcela.parcelaValorTotal),
      0
    );

    yield put(
      pdvActions.atualizarItemPagamento({
        ...itemPagamento,
        valorEntrada,
        valorRecebido,
        valorTotal: valorTotalDocumento,
        valorTroco: 0,
        parcelas
      })
    );
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* atualizarValorRecebido({ valorRecebido }) {
  try {
    const { venda, itemPagamento } = yield select(state => state.pdv);
    yield put(
      pdvActions.atualizarItemPagamento({
        ...itemPagamento,
        valorRecebido,
        valorTroco: currency(valorRecebido - venda?.valorTotal)
      })
    );
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* atualizarParcelaFechamento({ parcela }) {
  try {
    const { itemPagamento } = yield select(state => state.pdv);

    if(parcela?.fatCartaobandeira?.id) parcela.fatCartaobandeiraId = parcela.fatCartaobandeira.id;

    const parcelas = [...itemPagamento.parcelas];
    const index = parcelas.indexOf(parcelas.find(x => x.uuid == parcela.uuid));
    parcelas[index] = { 
      ...parcela,
      cartaoAutorizacao: parcela.documentoNumero
    };

    yield put(
      pdvActions.atualizarItemPagamento({
        ...itemPagamento,
        parcelas
      })
    );
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

function* montarParcelas(payload) {
  const isOnline = yield select(getApplicationIsOnline());

  const { tipoDocumento } = yield select(state => state.pdv);
  const { valorTotalDocumento } = payload;

  if (valorTotalDocumento === 0 || tipoDocumento === TIPO_DOCUMENTO.ORCAMENTO) {
    return [];
  }

  const parcelas = (payload?.parcelas ?? [])?.length > 0 ? [...payload?.parcelas] : [];
  if (payload?.valorEntrada > 0 && parcelas?.length > 0) {
    parcelas[0].parcelaValor = payload?.valorEntrada;
  }

  const payloadCalculaParcela = {
    condicaoPagamentoEntradaId: payload?.meioPagamentoEntrada?.id ?? null,
    condicaoPagamentoParcelasId: payload?.meioPagamento?.id,
    idCondicaopagamento: payload?.condicaoPagamento?.id,
    parcelas: payload?.valorEntrada > 0 ? parcelas: [],
    valorTotalDocumento
  };

  const { data } = isOnline
    ? yield call(
        api.post,
        `${API_BASE_URL}/v1/faturamento/subrotina/parcela/`,
        payloadCalculaParcela
      )
    : yield call(api.post, `${API_LOCAL_URL}/faturamento/subrotina/parcela`, payloadCalculaParcela);

  const parcelasCalculadas = [
    ...data?.map((x, index) => ({ ...x, id: index + 1, uuid: x?.uuid ?? uuid() }))
  ];
  return parcelasCalculadas;
}

export function* iniciaPagamento() {
  try {
    yield put(pdvActions.setShowModalLoadingMensagem({ show: true }));
    yield put(pdvActions.updateLoadingMensagem('Iniciando pagamento, aguarde...'));
    const { venda, itemPagamento, multiplosPagamentos } = yield select(state => state.pdv);

    // if (!venda?.importado) {
      yield call(calculaImpostos);
    // }

    if (itemPagamento !== null) {
      const { meioPagamento, condicoes, valorEntrada } = itemPagamento;
      const condicoesCalculadas = yield call(montarCondicaoPagamento, {
        ...meioPagamento,
        condicoes
      });

      if(!multiplosPagamentos) {
        const parcelas = yield call(montarParcelas, {
          ...itemPagamento,
          valorTotalDocumento: venda?.valorTotal,
          valorEntrada
        });
  
        const valorRecebido = parcelas?.reduce(
          (valorTotal, parcela) => (valorTotal += parcela.parcelaValorTotal),
          0
        );

        yield put(
          pdvActions.atualizarItemPagamento({
            ...itemPagamento,
            condicoes: [...condicoesCalculadas],
            parcelas: [...parcelas.map(p => ({ ...p, uuid: p?.uuid ?? uuid() }))],
            valorTotalDocumento: venda?.valorTotal,
            valorEntrada,
            valorTroco: 0,
            valorRecebido
          })
        );
      }
    }

    yield put(pdvActions.setStatus(STATUS_PDV.IDENTIFICACAO));
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  } finally {
    yield put(pdvActions.setShowModalLoadingMensagem({ show: false }));
    yield put(pdvActions.updateLoadingMensagem(''));
  }
}

function* calculaImpostos(itemNovo = null) {
  const modeloVenda = yield select(state => state.pdv.venda);
  const isOnline = yield select(getApplicationIsOnline());

  const itensPostado = itemNovo
    ? [...(modeloVenda?.itens?.filter(x => x.uuid !== itemNovo?.uuid) ?? []), itemNovo]
    : [...modeloVenda?.itens];

  if (itensPostado?.length === 0) {
    return;
  }

  const payload = {
    fatCondicaopagamento: modeloVenda?.fatCondicaopagamento,
    fatVendedor: modeloVenda?.fatVendedor,
    finMeiopagamento: modeloVenda?.finMeiopagamento,
    fisOperacao: modeloVenda?.fisOperacao,
    intCliente: modeloVenda?.intCliente,
    intFornecedorId: null,
    itens: [
      ...itensPostado.map((item, index) => ({
        ...baseMontaItem(item),
        fatVendedor: modeloVenda?.fatVendedor,
        produtoEstArmazemId: item?.produtoEstArmazem?.id,
        index
      }))
    ]
  };

  let itensCalculados = [];
  try {
    const {
      data: { itens }
    } = yield call(
      api.post,
      isOnline
        ? `${API_BASE_URL}/v1/faturamento/subrotina/calculaimpostos`
        : `${API_TERMINAL_URL}/subrotina/calculaimpostos`,
      payload
    );

    itensCalculados = [...itens];
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }

  if (itensCalculados?.length === 0) {
    return;
  }

  itensCalculados.forEach(item => {
    const itemProcessado = itensPostado?.find(x => x.uuid === item.uuid);
    item.produtoEstProduto.saldoEstoque = itemProcessado.produtoEstProduto.saldoEstoque
    item.produtoEstProduto.estProdutoImposto[0].estoqueEstArmazem = itemProcessado.produtoEstProduto.estProdutoImposto[0].estoqueEstArmazem
  })

  console.log({ itensCalculados });

  if (modeloVenda.valorDesconto !== 0 || modeloVenda.valorAcrescimo !== 0) {
    itensCalculados.forEach(item => {
      let result = modeloVenda.itens.find(o1 => o1.uuid === item.uuid);
      if (result) {
        if (result.itemValorAcrescimo !== 0) {
          item.itemValorAcrescimo = result.itemValorAcrescimo;
        }
        if (result.itemValorDesconto !== 0) {
          item.itemValorDesconto = result.itemValorDesconto;
        }
      }
    });
  }

  if (itemNovo) {
    const itemProcessado = itensCalculados?.find(x => x.uuid === itemNovo.uuid);

    const { produtoEstProduto, produtoEstProdutoGrade, produtoEstArmazem, saldoEstoque } = itemNovo;
    const itemNovoCalculado = {
      ..._.merge({ ...itemNovo }, itemProcessado),
      id: null,
      _id: null,
      produtoEstProduto: produtoEstProduto,
      produtoEstProdutoGrade: produtoEstProdutoGrade,
      produtoEstArmazem: produtoEstArmazem,
      saldoEstoque: saldoEstoque,
      itemEstoque: saldoEstoque?.estoqueReal ?? 0,
      itemValorUnitario: itemProcessado?.itemValorUnitario,
      itemValorTotal: calculaValorTotalItem(itemProcessado)
    };

    // yield put(pdvActions.atualizarItens(itensCalculados?.filter(x => x.uuid !== itemNovo.uuid)));

    return [itemNovoCalculado];
  } else {
    yield put(pdvActions.atualizarItens(itensCalculados));
  }
}

function* showModalExpedicao() {
  yield put(
    pdvActions.setShowModalExpedicaoMercadoria({
      show: true,
      confirmation: true
    })
  );
}

function* fecharVenda() {
  try {
    console.log('fecharVenda=====>', new Date().toISOString());

    const terminal = yield select(state => state?.app?.terminal);
    const { usuario: usuarioLogado, empresa } = yield select(state => state.auth);
    const { venda, tipoDocumento, itemPagamento, multiplosPagamentos } = yield select(
      state => state.pdv
    );

    const isOnline = yield select(getApplicationIsOnline());
    let parcelas = venda?.parcelas ? [...venda?.parcelas] : [...itemPagamento?.parcelas];
    const { permiteFechamento, valorPago } = yield select(selectorFechamento(parcelas));

    if (!permiteFechamento) {
      toast.warning('Informar dados de pagamento!');
      yield put(pdvActions.setStatus(STATUS_PDV.IDENTIFICACAO));
      return;
    }

    const permiteEstoqueNegativo = yield select(selectorParametroAsBoolean('PF021'));

    let verificaEstoqueNegativo = false;
    let verificaNaoPermiteVenda = false;
    
    venda.itens.forEach(item => {
      if(item.itemCancelado) return;
      let estoqueDisponivel;
      const allItens = venda.itens.filter(i => i.produtoEstProdutoId === item.produtoEstProdutoId);
      const quantidadeTotal = allItens.filter(i => !i.itemCancelado).reduce((total, item) => total + item.itemQuantidade, 0);
      if(item?.saldoEstoque && Array.isArray(item?.saldoEstoque)) {
        const res = item?.saldoEstoque?.filter(estoque => estoque.intEmpresaId === empresa.id);
        if(res.length !== item.saldoEstoque.length) item.saldoEstoque = res;
        estoqueDisponivel = item.saldoEstoque?.reduce((tot, item) => (tot += item.estoqueReal), 0) - item.saldoEstoque?.reduce((tot, item) => (tot += item.estoqueReservado), 0);
        if(estoqueDisponivel < item.itemQuantidade || estoqueDisponivel < quantidadeTotal) {
          verificaEstoqueNegativo = true;
          item.estoqueInsuficiente = true;
        }
      }
      else if(item?.produtoEstProduto.estSaldoestoque && Array.isArray(item?.produtoEstProduto.estSaldoestoque)) {
        const res = item?.produtoEstProduto.estSaldoestoque?.filter(estoque => estoque.intEmpresaId === empresa.id);
        if(res.length !== item?.produtoEstProduto.estSaldoestoque.length) {
          item.produtoEstProduto.estSaldoestoque = res;
        }
        if(venda.importado && tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA) 
          estoqueDisponivel = item?.produtoEstProduto.estSaldoestoque?.reduce((tot, item) => (tot += item.estoqueReal), 0) - item?.produtoEstProduto.estSaldoestoque?.reduce((tot, item) => (tot += item.estoqueReservado), 0) + venda.itens?.filter(x => ((x.id || x?.itemImportado) && x.produtoEstProdutoId === item.produtoEstProdutoId && item.itemCancelado === false))?.reduce((valorTotal, item) => (valorTotal += item.itemQuantidade), 0)
          else estoqueDisponivel = item?.produtoEstProduto.estSaldoestoque?.reduce((tot, item) => (tot += item.estoqueReal), 0) - item?.produtoEstProduto.estSaldoestoque?.reduce((tot, item) => (tot += item.estoqueReservado), 0) + venda.itens?.filter(x => ((x.id && x.produtoEstProdutoId === item.produtoEstProdutoId)))?.reduce((valorTotal, item) => (valorTotal += item.itemQuantidade), 0);; 
          if(estoqueDisponivel < item.itemQuantidade || estoqueDisponivel < quantidadeTotal) {
          verificaEstoqueNegativo = true;
          item.estoqueInsuficiente = true;
        }
      }
      else if(item?.saldoEstoque && !Array.isArray(item?.saldoEstoque)) {      
        estoqueDisponivel = item.saldoEstoque.estoqueReal - item.saldoEstoque.estoqueReservado;
        if(estoqueDisponivel < item.itemQuantidade || estoqueDisponivel < quantidadeTotal) {
          verificaEstoqueNegativo = true;
          item.estoqueInsuficiente = true;
        }
      } else if(item.estoqueDisponivel || item.estoqueDisponivel === 0) {
        estoqueDisponivel = item.estoqueDisponivel + venda?.itens.filter(i => (i?.produtoEstProdutoId === item?.produtoEstProdutoId) && i.itemCancelado).reduce((tot, item) => (tot += item.itemQuantidade), 0);
        if(estoqueDisponivel < item.itemQuantidade || estoqueDisponivel < quantidadeTotal) {
          verificaEstoqueNegativo = true;
          item.estoqueInsuficiente = true;
        }
      } else if(!item?.saldoEstoque) {
        verificaEstoqueNegativo = true;
        item.estoqueInsuficiente = true;
      }
      if(!item?.produtoEstProduto?.estProdutoImposto[0]?.permiteVenda && tipoDocumento !== TIPO_DOCUMENTO.ORCAMENTO) {
        verificaNaoPermiteVenda = true;
      }
    });

    if (!permiteEstoqueNegativo && verificaEstoqueNegativo && tipoDocumento !== TIPO_DOCUMENTO.ORCAMENTO)
    return toast.warning(
      'De acordo com o parâmetro PF021, não é permitida a venda com estoque insuficiente!'
    );

    if(verificaNaoPermiteVenda) 
    return toast.warning(
      'Há itens que não são permitido venda!'
    );

    if (venda?.itens?.length === 0) {
      toast.warning('Nenhum item vendido!');
      yield put(pdvActions.setStatus(STATUS_PDV.VENDA));
      return;
    }

    const informarVendedor = yield select(selectorParametroAsBoolean('PF024'));
    if (informarVendedor && !venda?.fatVendedor) {
      toast.warning('Informar vendedor');
      yield put(
        pdvActions.setShowModalConsultaVendedor({
          show: true,
          confirmation: true
        })
      );
      return;
    }

    const expedicaoNaoInformada = venda?.itens?.every(x => !x?.omsModalidadeExpedicao);
    const expedicaoFutura = venda?.itens?.every(x => x?.omsModalidadeExpedicao?.controleEntrega);

    if (expedicaoNaoInformada && tipoDocumento !== TIPO_DOCUMENTO.ORCAMENTO) {
      toast.warning('Confirme a(s) expedição do(s) produto(s).');
      yield call(showModalExpedicao);
      return;
    }

    if (expedicaoFutura && !venda?.intCliente) {
      toast.warning(
        'Um ou mais itens deste documento tem expedição futura, é necessário informar um cliente pré-cadastrado.'
      );
      yield call(showModalExpedicao);
      return;
    }

    if (expedicaoFutura && venda?.intCliente && !venda.entregaIntClienteEnderecoId) {
      toast.warningExpedicao(
        'Um ou mais itens deste documento tem expedição futura, é necessário informar um endereço pré-cadastrado de entrega.'
      );
      yield put(pdvActions.setShowModalEnderecoCliente({ show: true, type: 'entrega' }));
      return;
    }

    /*
    if (expedicaoFutura && venda?.intCliente && !venda?.entregaIntClienteEnderecoId) {
      toast.warning(
        'Um ou mais itens deste documento tem expedição futura, é necessário informar um endereço pré-cadastrado de entrega.'
      );
      dispatch(
        actions.setShowModalEnderecoCliente({
          show: true,
          type: 'entrega',
          data: {
            descricao: 'Entrega',
            logradouro: venda?.entregaLogradouro,
            logradouroNumero: venda?.entregaLogradouroNumero,
            bairro: venda?.entregaBairro,
            cep: venda?.entregaCep,
            intCidade: venda?.entregaIntCidade,
            uf: venda?.entregaUf
          }
        })
      );

      return;

      // const toastInstance = toast.loading('Atualizando endereço de entrega, aguarde ....')
      // try {
      //   const { data: enderecos } = yield call(api.get,
      //     `${API_NODE_URL}/integracao/cliente/${venda?.intCliente?.id}/enderecos/`
      //   );

      //   const enderecoEncontrado = enderecos?.find( x => x.cep === venda.entregaCep);

      //   yield call(api.post,
      //     `${API_NODE_URL}/integracao/cliente/${venda?.intCliente?.id}/enderecos`,
      //     {
      //       ...values,
      //       intClienteId: venda?.intCliente?.id ?? null
      //     }
      //   )
      // } catch (error) {

      // } finally{
      //   toastInstance.close()
      // }
    }
    */

    if (venda?.parcelas?.length === 0 && tipoDocumento !== TIPO_DOCUMENTO.ORCAMENTO) {
      yield put(pdvActions.adicionarPagamento());
    }

    //PARCELAS - recalcula devido o desconto e acrescimo do fechamento
    if (valorPago !== venda?.valorTotal && !venda?.multiplosPagamentos) {
      try {
        yield put(pdvActions.setShowModalLoadingMensagem({ show: true }));
        yield put(pdvActions.updateLoadingMensagem('Atualizando pagamentos, aguarde...'));

        const parcelasCalculadas = yield call(montarParcelas, {
          ...itemPagamento,
          valorTotalDocumento: venda?.valorTotal,
          valorEntrada: 0
        });

        parcelas = [...parcelasCalculadas];
      } finally {
        yield put(pdvActions.setShowModalLoadingMensagem({ show: false }));
        yield put(pdvActions.updateLoadingMensagem(''));
      }
    }

    const exigeCliente = parcelas?.every(
      x => !x?.finMeiopagamento?.entraQuitado && !x?.finMeiopagamento?.tef
    );
    if (
      tipoDocumento !== TIPO_DOCUMENTO.ORCAMENTO &&
      exigeCliente &&
      !venda?.intCliente &&
      parcelas?.length > 0
    ) {
      toast.warning('É preciso informar um cliente para vendas à prazo');
      yield put(pdvActions.setShowModalConsultaCliente({ show: true, confirmation: true }));
      return;
    }

    if (
      TIPO_DOCUMENTO.DOCUMENTOSAIDA &&
      venda?.documentoModelo === MODELO_DOCUMENTO.NFCE &&
      (!venda?.intCliente || venda?.pfCpf)
    ) {
      const empresa = yield select(state => state.auth.empresa);
      let exigeClienteNaVenda = false;
      if (venda?.valorTotal >= 500 && empresa?.uf === 'BA') {
        exigeClienteNaVenda = true;
        toast.warning(
          `Conforme artigo 37-B do RICMS BA, nas vendas de mercadorias </br> a não contribuintes do ICMS com valor superior a R$500,00 </br> é obrigatório informar CPF ou CNPJ!`
        );
      } else if (venda?.valorTotal >= 3000 && empresa?.uf === 'MG') {
        exigeClienteNaVenda = true;
        toast.warning(
          `Conforme Decreto 47.562/2018 art. 36-C da SEFAZ MG  </br>  é obrigatório identificar o consumidor nas vendas  </br>  a partir de R$ 3.000,00`
        );
      } else if (venda?.valorTotal >= 1000 && empresa?.uf === 'MT') {
        exigeClienteNaVenda = true;
        toast.warning(
          `Conforme decreto 384/2020 da SEFAZ MT, </br> é obrigatório identificar o consumidor nas vendas  </br> a partir de R$ 1.000,00`
        );
      }

      if (exigeClienteNaVenda) {
        yield put(pdvActions.setShowModalIdentificarCliente({ show: true }));
        return;
      }
    }

    if (tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA) {
      const validaPagamentoTef = parcelas?.some(
        x =>
          (x?.finMeiopagamento?.tef &&
            x?.finMeiopagamento?.tefModalidade === null &&
            x?.documentoNumero === '') ||
          (x?.finMeiopagamento?.tef && !x?.fatCartaobandeira)
      );

      if (validaPagamentoTef) {
        if (multiplosPagamentos) {
          yield put(pdvActions.setShowModalListaPagamentos({ show: true, confirmation: true }));
          return;
        } else {
          yield put(pdvActions.setShowModalConfirmaPagamento({ show: true, confirmation: true }));
          return;
        }
      }

      let parcelasTef = parcelas.filter(
        x => x?.finMeiopagamento?.tef && x?.finMeiopagamento?.tefModalidade !== null
      );
      // if (parcelasTef?.length > 0 && venda?.documentoModelo !== MODELO_DOCUMENTO.NFCE) {
      //   toast.warning('Pagamento TEF não permitido para operação que não seja NFCE!');
      //   return;
      // }

      console.log({ parcelasTef });
      if (parcelasTef?.length > 0 && venda?.documentoModelo === MODELO_DOCUMENTO.NFCE) {
        const parametroTef = true; //yield select(selectorParametroAsBoolean('PF047'));
        if (!parametroTef) {
          toast.warning('Parâmetro PF047 [TEF] não está ativo, verificar no cadastro da empresa!');
          return;
        }

        const multiplosPagamentosTef = parcelasTef?.length > 1;
        const numeroDocumento =
          venda?.documentoNumero?.toString() ??
          Math.floor(100000 + Math.random() * 900000)?.toString();

        const parcelasTefNaoEfetuadas = parcelasTef?.filter(x => !x?.documentoNumero);

        console.log({ multiplosPagamentosTef, parcelasTefNaoEfetuadas });
        if (parcelasTefNaoEfetuadas?.length > 0) {
          for (const parcela of parcelasTefNaoEfetuadas) {
            try {
              yield put(
                appActions.setShowModalMenuTef({
                  show: true,
                  title: 'Pagamento TEF',
                  inicializado: false,
                  administracao: false,
                  parcela
                })
              );

              yield call(signalr.invokeInicializaTef);
              yield call(
                signalr.invokeEfetuaPagamentoTef,
                numeroDocumento,
                parcela?.parcelaValor,
                parcela?.finMeiopagamento?.tefModalidade ?? null,
                multiplosPagamentosTef
              );
              const parcelaIndex = parcelas?.findIndex(x => x.uuid === parcela.uuid);
              if (parcelaIndex !== -1) {
                parcelas[parcelaIndex].pagamentoEfetuado = false;
                const transacaoTef = yield select(state => state.app.transacaoTef);
                if (transacaoTef?.nsu) {
                  parcelas[parcelaIndex].cartaoAutorizacao  = transacaoTef?.nsu;
                  parcelas[parcelaIndex].pagamentoEfetuado =
                    transacaoTef?.pagamentoEfetuado ?? false;

                  yield put(appActions.imprimirComprovanteTef(numeroDocumento));
                  yield put(appActions.resetModalMenuTef());
                } else {
                  yield call(toast.warning, 'Pagamento tef não efetuado!');
                  return;
                }
              }
            } catch (error) {
              console.log('error', error);
              if (
                multiplosPagamentosTef &&
                parcelas?.every(x => !x?.pagamentoEfetuado && x?.documentoNumero)
              ) {
                yield call(signalr.invokeConfirmaPagamentoTef, numeroDocumento, false);
              }
              yield put({
                type: APP_ERROR,
                error
              });
              return;
            } finally {
              yield put(
                appActions.setShowModalMenuTef({
                  show: false,
                  inicializado: false,
                  imprimindo: false,
                  administracao: false,
                  tef: null
                })
              );
            }
          }
        }

        const parcelasAguardandoConfirmacao = parcelasTef?.filter(
          x => !x?.pagamentoEfetuado && x?.documentoNumero
        );
        console.log({ parcelasAguardandoConfirmacao });
        if (parcelasAguardandoConfirmacao?.length > 0) {
          // for (const parcela of parcelasAguardandoConfirmacao) {
          //   const parcelaIndex = parcelas?.findIndex(x => x.uuid === parcela.uuid);
          //   console.log({ parcelaIndex, parcelas });
          //   if (parcelaIndex !== -1) {
          //     parcelas[parcelaIndex].pagamentoEfetuado = true;
          //   }
          // }

          console.log('Confirma');

          yield call(signalr.invokeConfirmaPagamentoTef, numeroDocumento, true);
        }
      }
    }

    yield put(pdvActions.setShowModalLoadingSalvamento({ show: true }));
    yield put(pdvActions.setLoadingSalvamento(true));

    const modeloDados = { ...venda, parcelas };
    const payload = {
      ...modeloDados,
      dataEmissao: yield select(getDataHoraAtualComTimeZone()),
      emitidoPorTerminal: !isWeb,
      intClienteId: modeloDados?.intCliente?.id,
      fisOperacaoId: modeloDados?.fisOperacao?.id ?? null,
      fatCondicaopagamentoId: modeloDados?.fatCondicaopagamento?.id || modeloDados?.finMeiopagamento?.fatCondicaopagamentoId,
      finMeiopagamentoId: modeloDados?.finMeiopagamento?.id ?? null,
      fatTabelavendaId: modeloDados?.fatTabelavenda?.id ?? null,
      fatVendedorId: modeloDados?.fatVendedor?.id ?? null,
      fatProfissionalId: modeloDados?.fatProfissional?.id ?? null,
      enderecoIntCidadeId: modeloDados?.enderecoIntCidade?.id ?? null,
      entregaIntCidadeId: modeloDados?.entregaIntCidade?.id ?? null,
      valorIsento: 0,
      valorNaoIncidencia: 0,
      valorTributoIbpt: 0,
      impostoIcmsBase: 0,
      impostoIcms: 0,
      impostoIcmsStBase: 0,
      impostoIcmsSt: 0,
      impostoIpiBase: 0,
      impostoIpi: 0,
      impostoPisBase: 0,
      impostoPis: 0,
      impostoCofinsBase: 0,
      impostoCofins: 0,
      impostoFcpUfDest: 0,
      impostoFcpStRet: 0,
      impostoIcmsDesonerado: 0,
      impostoIcmsUfDest: 0,
      impostoIcmsUfRemet: 0,
      impostoIpiDevolvido: 0,
      itens: [...modeloDados?.itens]?.map(item => {
        const produtoEstArmazemId = item?.produtoEstArmazemId ?? item?.produtoEstArmazem?.id;

        const itemNovo = {
          ...item,
          saldoEstoque: null,
          fisModeloTributacaoIcms: null,
          fisModeloTributacaoIpi: null,
          fisModeloTributacaoPisCofins: null,
          produtoEstProduto: null,
          produtoEstArmazem: null,
          produtoEstArmazemId,
          _terminal: JSON.stringify({
            identificacaoTerminal: terminal,
            ipLocalTerminal: `${API_LOCAL_URL}`?.replace('http://', '')?.replace(':3335/api', '')
          }),
          _terminal_importacao: item?._terminal_importacao
            ? JSON.stringify(item?._terminal_importacao)
            : null
        };
        delete itemNovo.uuid;
        delete itemNovo.produtoEstProduto;
        delete itemNovo.produtoEstArmazem;
        delete itemNovo.saldoEstoque;
        delete itemNovo.fisModeloTributacaoIcms;
        delete itemNovo.fisModeloTributacaoIpi;
        delete itemNovo.fisModeloTributacaoPisCofins;
        //delete itemNovo.itemCfop;

        return itemNovo;
      }),
      parcelas: [...modeloDados?.parcelas]?.map(parcela => {
        return {
        ...parcela,
        finMeiopagamento: null,
        // documentoNumero: parcela?.documentoNumero
        //   ? parcela?.documentoNumero
        //   : itemPagamento?.documentoNumero ?? '',
        // fatCartaobandeiraId: parcela?.fatCartaobandeira
        //   ? parcela?.fatCartaobandeira?.id ?? null
        //   : itemPagamento?.fatCartaobandeira?.id ?? null
      }})
    };

    delete payload.fisOperacao;
    delete payload.fatCondicaopagamento;
    delete payload.finMeiopagamento;
    delete payload.fatVendedor;
    delete payload.fatProfissional;
    delete payload.fatTabelavenda;
    delete payload.creditoCliente;
    delete payload.regraComercial;
    delete payload.intCliente;
    delete payload.enderecoIntCidade;

    delete payload.bloqueioEnum;
    delete payload.bloqueioEnumList;
    delete payload.bloqueioList;
    delete payload.faturadoEnum;
    delete payload.faturadoEnumList;
    delete payload.faturadoList;
    delete payload.situacaoEnum;
    delete payload.situacaoEnumList;
    delete payload.situacaoList;

    // yield call(salvarDocumento, tipoDocumento, payload);
    // TODO: REVISAR TRATAMENTO DE ERRO AO SALVAR
    // yield race([take(FECHAR_VENDA_SUCCESS), take(FECHAR_VENDA_ERROR)]);
    // const { documentoSalvo } = yield select(state => state.pdv);
    // if (documentoSalvo?.type === 'error') {
    //   return;
    // }

    //ENVIAR/SALVAR

    let dadosFechamento = null;
    try {
      pdvService.setStrategy(tipoDocumento);
      const { data: response } = yield call(pdvService.enviarDocumento.bind(pdvService), {
        isOnline,
        ...payload
      });

      const documentoLiberado = !Array.isArray(response) || !response[0].solucao;
      const mensagem = documentoLiberado ? '' : response[0].solucao;
      const documentoSalvo = documentoLiberado ? { ...response } : { ...response[0]?.data };

      if (isOnline && !isWeb && !payload?.id) {
        try {
          yield call(api.post, `${API_LOCAL_URL}/painelcontrole/autosequencia/atualizar`, {
            tipoDocumento,
            serie:
              tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA
                ? documentoSalvo?.documentoSerie
                : documentoSalvo?.serie,
            modelo:
              tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA
                ? documentoSalvo?.documentoModelo
                : null,
            numero:
              tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA
                ? documentoSalvo?.documentoNumero
                : documentoSalvo?.numero
          });
        } catch (error) {
          console.error(error);
        }
      }

      const documentoId = documentoSalvo?.id ?? documentoSalvo?._id;
      const isDocumentoOffline = documentoSalvo?._id !== null && documentoSalvo?.id === null;

      if (documentoLiberado) {
        dadosFechamento = {
          action: PDV_ACTION.SALVO,
          documento: documentoSalvo,
          id: documentoId,
          isDocumentoOffline,
          serieDocumento: documentoSalvo?.serie ?? documentoSalvo?.documentoSerie,
          numeroDocumento: documentoSalvo?.numero ?? documentoSalvo?.documentoNumero,
          bloqueio: 0,
          mensagem
        };

        yield put(pdvActions.fecharVendaSuccess(dadosFechamento));
      } else {
        dadosFechamento = {
          action: PDV_ACTION.BLOQUEADO,
          documento: documentoSalvo,
          id: documentoId,
          isDocumentoOffline,
          serieDocumento: documentoSalvo?.serie ?? documentoSalvo?.documentoSerie,
          numeroDocumento: documentoSalvo?.numero ?? documentoSalvo?.documentoNumero,
          bloqueio: documentoSalvo?.bloqueio,
          mensagem
        };
        yield put(pdvActions.fecharVendaSuccess(dadosFechamento));
      }

      yield call(updateImportacao, {
        tipoDocumento,
        payload
      });
    } catch (error) {
      dadosFechamento = {
        action: PDV_ACTION.ERRO,
        numeroDocumento: null,
        mensagem: normalizeError(error)
      };
        yield put(pdvActions.fecharVendaError(dadosFechamento));
        yield put(pdvActions.setLoadingSalvamento(false));
    }
    
    if (!dadosFechamento || dadosFechamento?.action === PDV_ACTION.ERRO) {
      yield put(pdvActions.setStatus(STATUS_PDV.IDENTIFICACAO));
      return;
    }

    //TRANSMITIR
    if (dadosFechamento?.documento?.documentoModelo === MODELO_DOCUMENTO.NFCE) {
      if (isOnline) {
        yield put(pdvActions.setLoadingTransmissao(true));

        let retry = true;
        let retryCounter = 0;
        if (dadosFechamento?.id) {
          do {
            try {
              const { data } = yield call(
                api.put,
                `${API_BASE_URL}/v1/faturamento/documentosaida/transmitir/${dadosFechamento?.id}`
              );

              if (data?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.TRANSMITIDO) {
                dadosFechamento.documento = { ...data };
                dadosFechamento.documentoChave = data?.nfeChave;
              }

              retry = false;
              break;
            } catch (error) {
              const errorMessage = normalizeError(error);
              if (errorMessage === 'Network Error') {
                if (retryCounter === 3) {
                  dadosFechamento = {
                    ...dadosFechamento,
                    action: PDV_ACTION.OFFLINE,
                    id: dadosFechamento?.id,
                    mensagem: `Tentativas de envio falhou`
                  };

                  yield put(pdvActions.fecharVendaError(dadosFechamento));

                  retry = false;
                  break;
                }

                retryCounter++;
                yield delay(DELAY_RETRY_MILESEGUNDOS);

                dadosFechamento = {
                  ...dadosFechamento,
                  action: PDV_ACTION.RETENTATIVA,
                  id: dadosFechamento?.id,
                  mensagem: `Conexão falhou, tentando ${retryCounter}/3, aguarde ...`
                };

                yield put(pdvActions.fecharVendaError(dadosFechamento));
              } else {
                dadosFechamento = {
                  ...dadosFechamento,
                  action: PDV_ACTION.OFFLINE,
                  id: dadosFechamento?.id,
                  mensagem: errorMessage
                };
                yield put(pdvActions.fecharVendaError(dadosFechamento));
                retry = false;
                break;
              }
            }
          } while (!retry);

          yield put(pdvActions.setLoadingTransmissao(false));
        }
      } else {
        const retornoOffline = yield call(gerarDocumentoOffline, { payload: dadosFechamento });

        dadosFechamento = {
          ...dadosFechamento,
          action: PDV_ACTION.SALVO,
          documentoChave: retornoOffline?.chaveNFe,
          documento: {
            ...dadosFechamento?.documento,
            nfeChave: retornoOffline?.chaveNFe
          },
          mensagem: `Documento gerado offline`
        };
        yield put(pdvActions.fecharVendaSuccess(dadosFechamento));
      }
    }

    if (
      dadosFechamento?.action !== PDV_ACTION.ERRO &&
      dadosFechamento?.action !== PDV_ACTION.OFFLINE
    ) {
      let payloadImpressao = _.merge(payload, dadosFechamento?.documento);

      if (payload?.id || payload?._id) {
        pdvService.setStrategy(tipoDocumento);
        const { data: response } = yield call(pdvService.getDocumento.bind(pdvService), {
          isOnline,
          ...payload
        });

        payloadImpressao = { ...response };
      }

      //IMPRIMIR
      yield call(imprimirDocumento, payloadImpressao, tipoDocumento);
    }
    // const servidor = print.getPreference(usuarioLogado?.id, tipoDocumento);
    // const imprimirVenda = servidor?.imprimirAposSalvar;
    // const imprimirNaoFiscal =
    //   [MODELO_DOCUMENTO.NFE, MODELO_DOCUMENTO.NFCE].indexOf(
    //     dadosFechamento?.documento?.documentoModelo
    //   ) === -1;
    // const imprimeExpedicao = servidor?.imprimirExpedicao;

    // if (imprimirVenda) {
    //   const { isConfirmed } = yield call(toast.confirm, 'ATENÇÃO', 'Deseja imprimir documento?', {
    //     icon: 'info',
    //     confirmButtonText: 'Imprimir',
    //     cancelButtonText: 'Cancelar'
    //   });

    //   if (isConfirmed) {
    //     if (imprimirNaoFiscal && servidor?.utilizaServidorImpressao) {
    //       yield put(pdvActions.setLoadingImpressao(true));

    //       //CARREGA ENDEREÇOS DOS PRODUTOS
    //       let produtosEnderecos = {};
    //       if (imprimeExpedicao) {
    //         produtosEnderecos = yield call(getProdutoExpedicao, modeloDados, isOnline);
    //       }

    //       try {
    //         yield call(
    //           print.execute,
    //           {
    //             ...modeloDados,
    //             serie: dadosFechamento?.serieDocumento,
    //             numero: dadosFechamento?.numeroDocumento,
    //             fatVendedor: modeloDados?.fatVendedor,
    //             intCliente: modeloDados?.intCliente,
    //             tipoDocumento: {
    //               [TIPO_DOCUMENTO.ORCAMENTO]: 'Orçamento',
    //               [TIPO_DOCUMENTO.PEDIDO]: 'Pedido',
    //               [TIPO_DOCUMENTO.DOCUMENTOSAIDA]: 'Documento auxiliar de venda'
    //             }[tipoDocumento],
    //             empresa,
    //             produtosEnderecos
    //           },
    //           {
    //             servidorImpressao: print.getServidorUrl(servidor),
    //             imprimeExpedicao
    //           }
    //         );
    //       } catch (error) {
    //         yield put({ type: APP_ERROR, error });
    //       } finally {
    //         yield put(pdvActions.setLoadingImpressao(false));
    //       }
    //     } else if (dadosFechamento?.documento?.documentoModelo === MODELO_DOCUMENTO.NFCE) {
    //       yield call(
    //         api.post,
    //         `${API_TERMINAL_URL}/api/documentosaida/imprimir/${dadosFechamento?.documentoChave}`
    //       );
    //     }
    //   }
    // } else {
    //   if (!imprimirNaoFiscal) {
    //     if (dadosFechamento?.documento?.documentoModelo === MODELO_DOCUMENTO.NFCE) {
    //       yield put(
    //         pdvActions.setShowModalPreviewDanfe({
    //           show: true,
    //           data: {
    //             documentoId: dadosFechamento?.id,
    //             documentoChave: dadosFechamento?.documentoChave
    //           }
    //         })
    //       );
    //     }
    //   }
    // }
    // yield put(pdvActions.setLoadingImpressao(false));
    yield put(pdvActions.setShowModalLoadingSalvamento({ show: true }));
    yield put(pdvActions.setLoadingSalvamento(false));
    yield put(pdvActions.setShowModalLoadingMensagem({ show: false }));
    yield put(pdvActions.limparVenda());
  } catch (error) {
    yield put(pdvActions.setShowModalLoadingSalvamento({ show: false }));
    yield put(pdvActions.setLoadingTransmissao(false));
    yield put(pdvActions.setLoadingSalvamento(false));
    yield put(pdvActions.setLoadingImpressao(false));
    yield put({ type: APP_ERROR, error });
  }
}

function* updateImportacao({ tipoDocumento, payload }) {
  const itensImportados = payload?.itens
    ?.filter(x => x._id_orcamento_item || x._id_pedido_item)
    ?.map(x => ({
      ...x,
      _terminal_importacao: x?._terminal_importacao ? JSON.parse(x?._terminal_importacao) : null
    }))
    ?.reduce((arrays, item) => {
      const terminalIndex = arrays.findIndex(
        x =>
          x?.terminal?.identificacaoTerminal === item?._terminal_importacao?.identificacaoTerminal
      );
      if (terminalIndex !== -1) {
        const updatedArrays = [...arrays];
        updatedArrays[terminalIndex] = {
          terminal: item?._terminal_importacao,
          itens: [...updatedArrays[terminalIndex].itens, item]
        };
        return [...updatedArrays];
      } else {
        return [
          ...arrays,
          {
            terminal: item?._terminal_importacao,
            itens: [item]
          }
        ];
      }
    }, []);

  if (itensImportados?.length > 0) {
    let urlAtualizacao = '';
    if (tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA) {
      urlAtualizacao = 'faturamento/pedido/atualiza-importacao';
    } else if (tipoDocumento === TIPO_DOCUMENTO.PEDIDO) {
      urlAtualizacao = 'faturamento/orcamento/atualiza-importacao';
    }
    const promisedImportados = itensImportados?.map(item => {
      return call(
        api.post,
        `http://${item?.terminal?.ipLocalTerminal ?? 'localhost'}:3335/api/${urlAtualizacao}`,
        item.itens
      );
    });
    yield all([...promisedImportados]);
  }
}

function* getProdutoExpedicao(modeloDados, isOnline) {
  let produtosEnderecos = {};
  let enderecos = [];
  if (isOnline) {
    const {
      data: { items }
    } = yield call(
      api.get,
      `${API_BASE_URL}/v1/estoque/produto/?$select=${[
        'id',
        'codigo',
        'descricao',
        'unidadeComercial'
      ].join(',')}&$expand=${['estProdutoArmazem($expand=estArmazem,estArmazemEndereco)'].join(
        ','
      )}&$filter=(${modeloDados?.itens
        .map(
          item =>
            `id eq ${
              item?.produtoEstProduto ? item?.produtoEstProduto?.id : item?.produtoEstProdutoId
            }`
        )
        .join(' or ')})`
    );
    enderecos = [...items];
  } else {
    const promiseProdutoItens = modeloDados?.itens?.map(item => {
      return call(
        api.get,
        `${API_LOCAL_URL}/estoque/produto/${
          item?.produtoEstProduto ? item?.produtoEstProduto?.id : item?.produtoEstProdutoId
        }`
      );
    });
    const data = yield all([...promiseProdutoItens]);
    enderecos = [data?.map(x => ({ ...x.data }))];
  }

  if (enderecos?.length > 0) {
    for (const item of modeloDados?.itens) {
      const endereco = enderecos?.find(({ estProdutoArmazem, ...produto }) => {
        return (
          produto?.id ===
            (item?.produtoEstProduto ? item?.produtoEstProduto?.id : item?.produtoEstProdutoId) &&
          estProdutoArmazem &&
          estProdutoArmazem?.length &&
          estProdutoArmazem?.[0]?.estArmazemId ===
            (item?.produtoEstArmazem ? item?.produtoEstArmazem?.id : item?.produtoEstArmazemId)
        );
      });
      let produtoUnidadeComercial = item.produtoEstProduto
        ? item.produtoEstProduto?.unidadeComercial
        : item?.produtoUnidadeComercial;
      let codigoProduto = item?.produtoEstProduto
        ? item?.produtoEstProduto?.codigo
        : item?.produtoCodigo;
      let descricaoProduto = item?.produtoEstProduto
        ? item?.produtoEstProduto?.descricao
        : item?.produtoDescricao;

      if (endereco) {
        if (!produtosEnderecos[endereco?.estProdutoArmazem?.[0]?.estArmazem?.descricao]) {
          produtosEnderecos[endereco?.estProdutoArmazem?.[0]?.estArmazem?.descricao] = [];
        }

        const [armazemUm, ...armazens] = endereco?.estProdutoArmazem;
        const itemSalvo = produtosEnderecos[armazemUm?.estArmazem?.descricao]
          ? produtosEnderecos[armazemUm?.estArmazem?.descricao]?.find(
              itemSalvo => itemSalvo?.descricao === descricaoProduto
            )
          : false;

        if (!itemSalvo) {
          produtosEnderecos[armazemUm?.estArmazem?.descricao].push({
            armazem: armazemUm?.estArmazem?.descricao,
            codigo: codigoProduto,
            descricao: descricaoProduto,
            unidadeVenda: produtoUnidadeComercial,
            enderecoProduto: [armazemUm?.estArmazemEndereco.endereco],
            quantidade: item?.itemQuantidade ?? 0
          });
        } else {
          itemSalvo.quantidade += item?.itemQuantidade ?? 0;
        }

        armazens.forEach(armazem => {
          if (produtosEnderecos[armazem?.estArmazem?.descricao]) {
            let itemEnderecado = produtosEnderecos[armazem?.estArmazem?.descricao]?.find(
              p => p.descricao === descricaoProduto
            );
            if (itemEnderecado)
              itemEnderecado.enderecoProduto.push(armazem?.estArmazemEndereco?.endereco);
          }
        });
      } else {
        if (!produtosEnderecos[item?.produtoEstArmazem?.descricao]) {
          produtosEnderecos[item?.produtoEstArmazem?.descricao] = [];
        }

        const itemSalvo = produtosEnderecos[item?.produtoEstArmazem?.descricao].find(
          itemSalvo => itemSalvo.descricao === descricaoProduto
        );

        if (!itemSalvo) {
          produtosEnderecos[item?.produtoEstArmazem?.descricao].push({
            armazem: item?.produtoEstArmazem?.descricao,
            codigo: codigoProduto,
            descricao: descricaoProduto,
            unidadeVenda: produtoUnidadeComercial,
            enderecoProduto: [],
            quantidade: item?.itemQuantidade ?? 0
          });
        } else {
          itemSalvo.quantidade += item.itemQuantidade ?? 0;
        }
      }
    }
  }

  return produtosEnderecos;
}

function* imprimirDocumento(documento, tipoDocumento) {
  const { empresa, usuario: usuarioLogado } = yield select(state => state.auth);
  const isOnline = yield select(getApplicationIsOnline());

  const servidor = print.getPreference(usuarioLogado?.id, tipoDocumento);
  const imprimirVenda = servidor?.imprimirAposSalvar;
  const imprimirNaoFiscal =
    [MODELO_DOCUMENTO.NFE, MODELO_DOCUMENTO.NFCE].indexOf(documento?.documentoModelo) === -1;
  const imprimeExpedicao = servidor?.imprimirExpedicao;

  if (imprimirVenda) {
    const { isConfirmed } = yield call(toast.confirm, 'ATENÇÃO', 'Deseja imprimir documento?', {
      icon: 'info',
      confirmButtonText: 'Imprimir',
      cancelButtonText: 'Cancelar'
    });
    //CARREGA ENDEREÇOS DOS PRODUTOS
    let produtosEnderecos = {};
    if (imprimeExpedicao) {
      produtosEnderecos = yield call(getProdutoExpedicao, documento, isOnline);
    }
    const payloadPrint = yield call(
      print.buildPayload,
      {
        ...documento,
        serie: documento?.serie ?? documento?.documentoSerie,
        numero: documento?.numero ?? documento?.documentoNumero,
        fatVendedor: documento?.fatVendedor,
        intCliente: documento?.intCliente,
        tipoDocumento: {
          [TIPO_DOCUMENTO.ORCAMENTO]: 'Orçamento',
          [TIPO_DOCUMENTO.PEDIDO]: 'Pedido',
          [TIPO_DOCUMENTO.DOCUMENTOSAIDA]: 'Documento auxiliar de venda'
        }[tipoDocumento],
        empresa,
        produtosEnderecos
      },
      imprimeExpedicao
    );

    if (isConfirmed) {
      if (imprimirNaoFiscal && servidor?.utilizaServidorImpressao) {
        yield put(pdvActions.setLoadingImpressao(true));

        try {
          yield call(print.execute, payloadPrint, {
            servidorImpressao: print.getServidorUrl(servidor)
          });
        } catch (error) {
          yield put({ type: APP_ERROR, error });
        } finally {
          yield put(pdvActions.setLoadingImpressao(false));
        }
      } else if (documento?.documentoModelo === MODELO_DOCUMENTO.NFCE) {
        yield call(api.post, `${API_TERMINAL_URL}/documentosaida/imprimir/${documento?.nfeChave}`);

        if (imprimeExpedicao) {
          yield put(pdvActions.setLoadingImpressao(true));
          try {
            yield call(print.printExpedicao, payloadPrint, {
              servidorImpressao: print.getServidorUrl(servidor)
            });
          } catch (error) {
            yield put({ type: APP_ERROR, error });
          } finally {
            yield put(pdvActions.setLoadingImpressao(false));
          }
        }
      }
    }
  } else {
    if (!imprimirNaoFiscal) {
      if (documento?.documentoModelo === MODELO_DOCUMENTO.NFCE) {
        yield put(
          pdvActions.setShowModalPreviewDanfe({
            show: true,
            data: {
              documentoId: documento?.id ?? documento?._id,
              documentoChave: documento?.nfeChave
            }
          })
        );
      }
    }
  }

  yield put(pdvActions.setLoadingImpressao(false));
}

function* gerarDocumentoOffline({ payload, imprimirAposGerar }) {
  let dadosFechamento = { ...payload };
  try {
    yield put(pdvActions.setLoadingTransmissao(true));
    try {
      const {
        data: { lote }
      } = yield call(api.get, `${API_LOCAL_URL}/painelcontrole/autosequencia/lote`);

      let documento = dadosFechamento?.documento;
      if (!dadosFechamento?.isDocumentoOffline) {
        const { data } = yield call(
          api.get,
          `${API_BASE_URL}/v1/faturamento/documentosaida/${dadosFechamento?.documento?.id}`
        );
        documento = { ...data };
      }

      const { data } = yield call(api.post, `${API_TERMINAL_URL}/documentosaida/gerar-xml`, {
        ...documento,
        nfeLote: lote
      });

      if (!data) {
        return;
      }

      try {
        if (dadosFechamento?.isDocumentoOffline) {
          yield call(
            api.put,
            `${API_LOCAL_URL}/faturamento/documentosaida/${dadosFechamento?.documento?._id}`,
            {
              ...documento,
              nfeChave: data?.chaveNFe,
              nfeLote: lote,
              documentoTransmissao: DOCUMENTO_TRANSMISSAO.AGUARDANDO_TRANSMISSAO
            }
          );
        } else {
          //TODO: validar se tem internet para atualizar o documento q foi gerado offline (xml)
          yield call(
            api.put,
            `${API_BASE_URL}/v1/faturamento/documentosaida/${dadosFechamento?.documento?.id}`,
            {
              ...documento,
              nfeChave: data?.chaveNFe,
              nfeLote: lote,
              documentoTransmissao: DOCUMENTO_TRANSMISSAO.AGUARDANDO_TRANSMISSAO
            }
          );

          dadosFechamento = {
            ...dadosFechamento,
            documento,
            action: PDV_ACTION.SALVO,
            documentoChave: data?.chaveNFe,
            documentoNumero: documento?.documentoNumero,
            mensagem: `Documento gerado offline`
          };
          yield put(pdvActions.fecharVendaSuccess(dadosFechamento));
        }
        if (imprimirAposGerar) {
          const { tipoDocumento } = yield select(state => state.pdv);
          yield call(
            imprimirDocumento,
            {
              ..._.merge(dadosFechamento?.documento, {
                nfeChave: data?.chaveNFe,
                nfeLote: lote
              })
            },
            tipoDocumento
          );
        }
      } catch (error) {
        return null;
      }

      return { ...data };
    } catch (error) {
      return null;
    }
  } catch (error) {
    dadosFechamento = {
      ...dadosFechamento,
      action: PDV_ACTION.ERRO,
      id: dadosFechamento?.id,
      numeroDocumento: null,
      mensagem: errorMessage
    };
    yield put(pdvActions.fecharVendaError(dadosFechamento));
  } finally {
    yield put(pdvActions.setLoadingTransmissao(false));
  }
}

function* transmitir(id, tentativas = 1) {
  const { data } = yield call(
    api.put,
    `${API_BASE_URL}/v1/faturamento/documentosaida/transmitir/${id}`
  );
  if (
    data?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.NAO_TRANSMITIDO ||
    data?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.EM_PROCESSAMENTO
  ) {
    yield call(transmitir, id, tentativas + 1);
  } else if (data?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.TRANSMITIDO) {
    const documentoChave = data?.nfeChave;
    return {
      documentoChave
    };
  }
}

function* importarOrcamento({ documentos, pathname, callback }) {

  const permiteEstoqueNegativo = yield select(selectorParametroAsBoolean('PF021'));
  const { tipoDocumento } = yield select(state => state.pdv);
  const { empresa } = yield select(state => state.auth);

  const verificaOrcamentoFechado = documentos.some(doc => doc.situacao === 0);
  if(verificaOrcamentoFechado) return toast.warning("documento fechado não pode ser exportado!");

  const toastInstance = toast.loading('Carregando documento(s), aguarde ...');
  let documentosSelecionados = [];
  let venda = {};
  try {
    const promiseDocumentos = documentos?.map(item => {
      return call(
        api.get,
        item?.isOffline
          ? `${API_LOCAL_URL}/faturamento/orcamento/${item?._id}`
          : `${API_BASE_URL}/v1/faturamento/orcamento/${item?.id}`
      );
    });
    const _documentos = yield all(promiseDocumentos);

    if (_documentos) {
      documentosSelecionados = [..._documentos.map(x => x.data)];
    }

    venda = {
      tipoDocumento: TIPO_DOCUMENTO.PEDIDO,
      venda: {
        ...documentosSelecionados[0],
        serie: null,
        numero: null,
        id: null,
        _id: null,
        itens: [
          ...documentosSelecionados?.map(documento =>
            documento?.itens
              ? documento?.itens
                  ?.filter(x => !x.itemCancelado)
                  ?.map(item => ({
                    ...item,
                    _terminal_importacao: documento?._terminal_importacao ?? null
                  }))
              : []
          )
        ]
          .flat()
          .map(item => ({
            ...item,
            id: null,
            fatOrcamentoItemId: item.id ?? null,
            //offline
            _id: null,
            _parent: null,
            _id_orcamento_item: item?._id ?? null
          })),
        parcelas: documentos?.[0]?.parcelas,
        importado: true
      }
    };

    let verificaEstoqueNegativo = false;
    
    venda.venda.itens.forEach(item => {
      const allItens = venda.venda.itens.filter(i => i.produtoEstProdutoId === item.produtoEstProdutoId);
      const quantidadeTotal = allItens.reduce((total, item) => total + item.itemQuantidade, 0);
      if(item?.saldoEstoque && Array.isArray(item?.saldoEstoque)) {
        const res = item?.saldoEstoque?.filter(estoque => estoque.intEmpresaId === empresa.id);
        if(res.length !== item.saldoEstoque.length) item.saldoEstoque = res;
        const estoqueDisponivel = item.saldoEstoque?.reduce((tot, item) => (tot += item.estoqueReal), 0) - item.saldoEstoque?.reduce((tot, item) => (tot += item.estoqueReservado), 0);
        if(estoqueDisponivel < item.itemQuantidade || estoqueDisponivel < quantidadeTotal) {
          verificaEstoqueNegativo = true;
          item.estoqueInsuficiente = true;
        }
      }
      else if(!item?.saldoEstoque) {
        verificaEstoqueNegativo = true;
        item.estoqueInsuficiente = true;
      }
    });

  } catch (error) {
    console.log(error);
    throw error;
    // dispatch({ type: APP_ERROR, error });
  } finally {
    toastInstance.close();
  }

  if (callback) {
    callback();
  }
  if (pathname !== '/pdv') {
    yield call(history.push, '/pdv', venda);
  } else {
    yield put(pdvActions.iniciarVenda(venda));
  }
}

function* importarPedido({}) {}

export default all([
  takeLatest(INICIAR_VENDA_REQUEST, iniciarVenda),
  takeLatest(ALTERAR_DOCUMENTO, alterarDocumento),
  takeLatest(ALTERAR_TIPO_DOCUMENTO_REQUEST, alterarTipoDocumento),
  takeLatest(FECHAR_VENDA_REQUEST, fecharVenda),
  takeLatest(INICIAR_PAGAMENTO, iniciaPagamento),
  takeLatest(GERAR_DOCUMENTO_OFFLINE, gerarDocumentoOffline),
  takeLatest(SELECIONAR_VENDEDOR, selecionarVendedor),
  takeLatest(SELECIONAR_PROFISSIONAL, selecionarProfissional),
  takeLatest(SELECIONAR_OPERACAO_REQUEST, selecionarOperacao),
  takeLatest(SELECIONAR_PRODUTO_REQUEST, selecionarProduto),
  takeLatest(SELECIONAR_ADD_PRODUTO_REQUEST, selecionaAddProduto),
  takeLatest(SELECIONAR_MEIO_PAGAMENTO_REQUEST, selecionarMeiopagamento),
  takeLatest(IDENTIFICAR_CLIENTE_REQUEST, identificarCliente),
  takeLatest(SELECIONAR_CONDICAO_PAGAMENTO_REQUEST, selecionarCondicaoPagamento),
  takeLatest(SALVAR_ENDERECO_CLIENTE_REQUEST, salvarEnderecoCliente),
  takeLatest(ADICIONAR_PRODUTO_REQUEST, adicionarItem),
  takeLatest(ATUALIZAR_PRECO_VENDA_PRODUTO, atualizarPrecoVendaProduto),
  takeLatest(CANCELAR_PRODUTO_REQUEST, cancelarItem),
  takeLatest(REMOVER_PRODUTO_REQUEST, removerItem),
  takeLatest(EDITAR_QUANTIDADE_ITEM_VENDIDO, editarQuantidadeItemVendido),
  takeLatest(ATUALIZAR_QUANTIDADE_REQUEST, atualizarQuantidade),
  takeLatest(ADICIONAR_PARCELAS_REQUEST, adicionarParcelas),
  takeLatest(ADICIONAR_PAGAMENTO_REQUEST, adicionarPagamento),
  takeLatest(REMOVER_PAGAMENTOS_REQUEST, removerPagamentos),
  takeLatest(SELECIONAR_OUTRO_PAGAMENTO, selecionarOutroPagamento),
  takeLatest(ATUALIZAR_VALOR_ENTRADA, atualizarValorEntrada),
  takeLatest(ATUALIZAR_VALOR_RECEBIDO, atualizarValorRecebido),
  takeLatest(ATUALIZAR_PARCELA_FECHAMENTO, atualizarParcelaFechamento),
  takeLatest(IMPORTAR_ORCAMENTO, importarOrcamento)
  // takeLatest(IMPORTAR_PEDIDO, importarPedido)
  // takeLatest(IMPORTAR_COPIAR_DOCUMENTO, importarCopiarDocumento)
]);
